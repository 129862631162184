import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase'
import db from '@/firebase/firestore'

import Landing from '../views/Landing.vue'
import Parents from '../views/Parents.vue'
import Teachers from '../views/Teachers.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Error from '../views/Error.vue'
import Changelog from '../views/Changelog.vue'

import Account from '../views/Account.vue'
import Play from '../views/Play.vue'
import Story from '../views/Story.vue'
import Creative from '../views/Creative.vue'

import Sandbox from '../views/Sandbox.vue'
import Editor from '../views/Editor.vue'
import Prototype from '../views/Prototype.vue'

Vue.use(VueRouter)

const routes = [
  /* ------------ Public Pages ------------ */
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/parents',
    name: 'Parents',
    component: Parents
  },
  {
    path: '/teachers',
    name: 'Teachers',
    component: Teachers
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/error/:error',
    name: 'Error',
    component: Error
  },
  {
    path: '/changelog',
    name: 'Changelog',
    component: Changelog,
  },
  /* ------------ Private Pages ------------ */
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      authenticated: true,
      approved: false,
      admin: false
    }
  },
  {
    path: '/play',
    name: 'Play',
    component: Play,
    meta: {
      authenticated: true,
      approved: true,
      admin: false,
    }
  },
  {
    path: '/play/story',
    name: 'Story',
    component: Story,
    meta: {
      authenticated: true,
      approved: true,
      admin: false,
      fullscreen: true
    }
  },
  {
    path: '/play/creative',
    name: 'Creative',
    component: Creative,
    meta: {
      authenticated: true,
      approved: true,
      admin: false,
      fullscreen: true
    }
  },
  /* ------------ Administrator Pages ------------ */
  {
    path: '/admin/sandbox',
    name: 'Sandbox',
    component: Sandbox,
    meta: {
      authenticated: true,
      approved: true,
      admin: true,
      fullscreen: true
    }
  },
  {
    path: '/admin/editor',
    name: 'Editor',
    component: Editor,
    meta: {
      authenticated: true,
      approved: true,
      admin: true,
      fullscreen: true
    }
  },
  {
    path: '/admin/prototype',
    name: 'Prototype',
    component: Prototype,
    meta: {
      authenticated: true,
      approved: true,
      admin: true,
      fullscreen: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve( (to,from,next) => {
  if (to.matched.some(rec => rec.meta.authenticated) ) {
    // ---------------------- Private Page ----------------------
    // We are attempting to load a private page. Authentication
    // is required.
    let user = firebase.auth().currentUser
    if (user && user.uid) { // user is logged in
      if (to.matched.some(rec => rec.meta.approved) ) {
        // ------------ Approved Page ------------
        // Approval is required so we query the database
        db.collection('users').doc(user.uid).get().then( snapshot => {
          if (snapshot.exists) {
            let data = snapshot.data()
            if (data.approved) {
              // ------------ Admin Page ------------
              // User has approval, we check for admin rights
              if (to.matched.some(rec => rec.meta.admin) ) {
                if (data.admin) {
                  next() // User is admin
                }
              } else {
                next() // Admin not required
              }
            } else {
              console.log('Approval required, NOT approved')
              next({ name: 'Account' })
            }
          } else {
            console.log('Could not verify approval status.')
          }
        }).catch( err => {
          console.log('Could not retrieve approval status')
        })
      } else {
        next() // Approval not required
      }
    } else { 
      // ------------ User Not Authenticated ------------
      console.log('Authentication required', 'Authed: NO')
      next({ name: 'Login' })
    }
  } else {
    // ---------------------- Public Page ----------------------
    next() // Public Page
  }
})

export default router