<template>
  <div class="d-flex flex-column">
    <v-bottom-navigation class="d-flex flex-column flex-md-row">
      <div class="attribution" >
          Bleep Bloop&trade; &copy; {{ new Date().getFullYear() }} — <strong>Farr Industries Corp.</strong>
          <router-link class="version pl-2" to="/changelog">({{ codename }}: {{ version }} {{ environment }})</router-link>
      </div>
      <div class="attribution">
          <!-- <router-link class="pl-4" to="/privacy">Privacy Policy</router-link> -->
          <!-- <router-link class="pl-4" to="/terms">Terms of Use</router-link> -->
      </div>
    </v-bottom-navigation>
  </div>
</template>

<script>
  export default {
    data: () => ({
      links: [
        'Home',
        // 'About Us',
        // 'Team',
        // 'Services',
        // 'Contact Us',
      ],
      codename: '',
      version: '',
      environment: '',
    }),
    created() {
      this.codename = process.env.VUE_APP_CODENAME
      this.version = process.env.VUE_APP_VERSION
      this.environment = process.env.VUE_APP_ENVIRONMENT
    },
  }
</script>

<style lang="scss" scoped>
  .attribution {
    height: inherit;
    opacity: 0.7;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: inherit;
    -ms-flex-pack: inherit;
    justify-content: inherit;
    line-height: normal;
    position: relative;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.0892857143em;
    .version {
      color: black;
    }
  }
</style>