<template>
  <v-container fluid pa-6>
    <v-card flat>
      <v-card-title>Changelog</v-card-title>
      <v-card-text>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel v-for="(log,index) in logs" :key="index">
            <v-expansion-panel-header disable-icon-rotate>
              {{ log.version }}
              <template v-slot:actions>
                <v-icon color="teal">mdi-check</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list nav dense shaped>
                <v-list-item-group color="primary">
                  <v-list-item
                    v-for="(feature, i) in log.features"
                    :key="i"
                  >
                    <v-list-item-icon>
                      <v-icon v-if="feature.toLowerCase().search('bug:') > -1">mdi-bug</v-icon>
                      <v-icon v-else>mdi-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-if="index==0">{{feature}}</v-list-item-title>
                      <v-list-item-title v-else><strike>{{feature}}</strike></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'ChangeLog',
  data() {
    return {
      panel: 0,
      logs: [
        // ------------ Next Release ------------
        // {
        //   version: '0.2.3+',
        //   features: [
        //     'Restructure (separate game from Vue?)',
        //     'Separate editor, website, game?
        //     'Object creator',
        //     'Animation creator',
        //   ]
        // },
        // ------------ Current Release ------------
        {
          version: '0.2.2',
          features: [
            'Engine: removed graphics performance testing',
            'Engine: load basic level',
          ]
        },
        // ------------ Previous Releases ------------
        {
          version: '0.2.1',
          features: [
            'Engine: graphics performance testing',
              'method 1: sprite background - 5.5',
              'method 2: canvas background - 5.7',
              'method 3: image background - 5.1',
          ]
        },
        {
          version: '0.2.0',
          features: [
            'Engine: p5 integration',
            'Engine: camera scrolling',
            'Engine: window resizing',
          ]
        },
        {
          version: '0.1.9',
          features: [
            'Engine: Game Engine UI',
          ]
        },
        {
          version: '0.1.8',
          features: [
            'Editor: Export images',
            'Editor: Grid Size/Resize',
            'Editor: Export spritesheet',
          ]
        },
        {
          version: '0.1.7',
          features: [
            'Editor: Save Level',
            'Editor: Load Level',
          ]
        },
        {
          version: '0.1.6',
          features: [
            'Editor: Brush drawing',
            'Editor: Grid Toggle',
            'Editor: Added Spritesheet (Serene Village)',
            'Editor: Disabled user select',
          ]
        },
        {
          version: '0.1.5',
          features: [
            'Editor: Complete overhaul and optimization',
            'p5 preload spritesheets',
            'copy sprite to sprites[]',
            'canvas grid + camera'
          ]
        },
        {
          version: '0.1.4',
          features: [
            'Editor: Basic layers'
          ]
        },
        {
          version: '0.1.3',
          features: [
            'Editor: Drawing from multiple spritesheets'
          ]
        },
        {
          version: '0.1.2',
          features: [
            'Editor: Loading palette'
          ]
        },
        {
          version: '0.1.1',
          features: [
            'Major Rework',
            'Basic game engine',
            'Creating advanced level editor',
            'New website design'
          ]
        },
        {
          version: '0.0.8',
          features: [
            'Implement background + clipping'
          ]
        },
        {
          version: '0.0.7',
          features: [
            'added background selection',
            'added screenshot feature',
          ]
        },
        {
          version: '0.0.6',
          features: [
            'bug: ghosting issue',
            'added authentication',
            'added approval system',
            'added changelog'
          ]
        },
        {
          version: '0.0.5',
          features: [
            'Added graphics to physics objects'
          ]
        },
      ]
    }
  }
}
</script>