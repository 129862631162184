<template>

</template>

<script>
import Matter from 'matter-js'
import p5 from 'p5'

export default {
  name: 'Sandbox',
  props: ['context'],
  methods: {  
    init(context) {
      // ----- Initialize p5
      const script = function (p5) {
        const b2d = null
        let grid = {
          scale: 0,
          width: 12+5,
          height: 8+1,
          offset: {
            x: 0,
            y: 0
          }
        }
        let items = []
        let bleepBloop = {
          gridX: 9,
          gridY: 5,
          direction: 9
        }
        var mk1 = []
        var images = []
        var keyDown = false
        var background = 1
        //
        var start = 0
        var orientation = ''
        var primary = {}
        var secondary = {}
        var padding = 10
        // ------ Matter Physics ------
        var engine = Matter.Engine.create()
        var world = engine.world
        // world.gravity.x = 0
        // world.gravity.y = 0
        var itemCategory = 32
        p5.preload = _ => {
            mk1['front'] = p5.loadImage('/assets/img/mk1-front.png')
            mk1['back'] = p5.loadImage('/assets/img/mk1-back.png')
            mk1['left'] = p5.loadImage('/assets/img/mk1-left.png')
            mk1['right'] = p5.loadImage('/assets/img/mk1-right.png')
            mk1['box'] = p5.loadImage('/assets/img/box.png')
            mk1['gobatron'] = p5.loadImage('/assets/img/gobatron.png')
            mk1['milkcrate'] = p5.loadImage('/assets/img/milkcrate.png')

            mk1['sketch1'] = p5.loadImage('/assets/img/sketch1.png')
            mk1['sketch2'] = p5.loadImage('/assets/img/sketch2.png')
            mk1['sketch3'] = p5.loadImage('/assets/img/sketch3.png')
            mk1['sketch4'] = p5.loadImage('/assets/img/sketch4.png')

            images['wall'] = p5.loadImage('/assets/img/2wall.png')
            images['floor'] = p5.loadImage('/assets/img/3floor.png')
            // 
            images['cabinet'] = p5.loadImage('/assets/img/15cabinet.png')
            images['workbench'] = p5.loadImage('/assets/img/16workbench.png')
            images['bucket'] = p5.loadImage('/assets/img/6bucket.png')
            images['bolt'] = p5.loadImage('/assets/img/7bolt.png')



        },

        p5.setup = _ => {
          var canvas = p5.createCanvas(context.canvas.width, context.canvas.height)
          // p5.noLoop()
          p5.background('#ffffff')
          // let ele = p5.createAudio('assets/audio/bonus.mp3');
          // let ele = p5.createAudio('assets/audio/bleep-bloop.wav');
          // ele.autoplay(true);
          p5.noStroke()
          //
          // var render = Matter.Render.create({
          //   element: document.body,
          //   engine: engine,
          //   options: {
          //     width: 800,
          //     height: 600
          //   }
          // });
          // Matter.Render.run(render);
          start = + new Date()
          // ------ Setup Grid ------
          if (canvas.width > canvas.height) {
            orientation = 'landscape'
            primary.x = 0
            primary.y = 0
            primary.width = canvas.width - Math.round(canvas.width/3)
            primary.height = canvas.height
            secondary.x = primary.width
            secondary.y = 0
            secondary.width = Math.round(canvas.width/3)
            secondary.height = canvas.height
            grid.scale = (primary.width-2*padding) / grid.width
            grid.offset.x = padding
            grid.offset.y = (canvas.height - (grid.scale * grid.height))/2
          } else {
            orientation = 'portrait'
            primary.x = 0
            primary.y = 0
            primary.width = canvas.width
            primary.height = canvas.height - Math.round(canvas.height/3)
            secondary.x = 0
            secondary.y = canvas.height - Math.round(canvas.height/3)
            secondary.width = canvas.width
            secondary.height = Math.round(canvas.height/3)
          }
          // ------ Matter Physics ------
          // add floors
          var floors = []
          let mid = grid.offset.x + (grid.width * grid.scale)/2
          let floorCategory = Matter.Body.nextCategory()
          let itemCategory = Matter.Body.nextCategory()
          let cordCategory = Matter.Body.nextCategory()
          let playerCategory = Matter.Body.nextCategory()
          
          for (var floor=1; floor<=grid.height+1; floor++) {
            let group = Matter.Body.nextGroup(true)
            // let category = Matter.Body.nextCategory()
            // console.log('group', group, category)
            if (floor == 9) {
              var pyramid = Matter.Composites.pyramid(600, 380,  9, 10, 0, 0, function(x, y) {
                return Matter.Bodies.rectangle(x, y, 12, 20,{
                  label: 'toy',
                  collisionFilter: {
                    group: floor,
                    category: itemCategory,
                    mask: itemCategory | playerCategory
                  }
                })
              })
              Matter.World.add(world, pyramid)
            }
            if (floor == 5) {
              // ------------ Create Box ------------
              Matter.World.add(world, 
                Matter.Bodies.rectangle(
                  grid.offset.x + 4 * grid.scale,
                  grid.offset.y + 4 * grid.scale,
                  grid.scale,
                  grid.scale,
                  {
                    label: 'box',
                    collisionFilter: {
                      group: floor,
                      category: itemCategory,
                      mask: itemCategory | playerCategory
                    }
                  }
                )
              )
              // ------------ Create Ball ------------
              Matter.World.add(world,
                Matter.Bodies.circle(215, 40, 15, {
                  label: 'ball',
                  collisionFilter: {
                    group: floor,
                    category: itemCategory,
                    mask: itemCategory | playerCategory
                  }
                })
              );
            }
            // ------------ Create Bucket ------------
            if (floor == 6) {
              // ----------------------
              var size = 30,
              x = 400,
              y = 350
              var partA = Matter.Bodies.rectangle(x, y, 30, 6)
              var partB = Matter.Bodies.rectangle(x-15, y-15, 6, 40)
              var partC = Matter.Bodies.rectangle(x+15, y-15, 6, 40)
              var compoundBodyA = Matter.Body.create({
                parts: [partA, partB, partC],
                label: "bucket",
                collisionFilter: {
                  group: floor,
                  category: itemCategory,
                  mask: itemCategory | playerCategory
                }
              });
              Matter.World.add(world, compoundBodyA)
              var bolts = Matter.Composites.stack(390, 250, 4, 11, 2, 5, function(x, y) {
                return Matter.Bodies.rectangle(
                  x,
                  y,
                  5,
                  5,
                  {
                    label: 'bolt',
                    collisionFilter: {
                      group: floor,
                      category: itemCategory,
                      mask: itemCategory | playerCategory
                    }
                  }
                )
              })
              Matter.World.add(world, bolts)
              //
              var coins = Matter.Composites.stack(500, 300, 20, 5, 20, 5, function(x, y) {
                return Matter.Bodies.rectangle(
                  x,
                  y,
                  10,
                  5,
                  {
                    label: 'coin',
                    collisionFilter: {
                      group: floor,
                      category: itemCategory,
                      mask: itemCategory | playerCategory
                    }
                  }
                )
              })
              // Matter.World.add(world, coins)

              // ------------ Create Ball ------------
              // Matter.World.add(world,
              //   Matter.Bodies.circle(215, 40, 15, {
              //     label: 'ball',
              //     collisionFilter: {
              //       group: floor,
              //       category: itemCategory,
              //       mask: itemCategory | playerCategory
              //     }
              //   })
              // );
            }
            // ------------ Create Floors ------------
            floors.push(
              Matter.Bodies.rectangle(mid, grid.offset.y+floor*grid.scale-grid.scale/2, grid.width*grid.scale, 5, {
                label: 'floor',
                isStatic: true,
                collisionFilter: {
                  group: floor,
                  // category: floorCategory
                }
              })
            )
          }
          Matter.World.add(world, floors);
          // Model walls
          Matter.World.add(world,
            Matter.Bodies.rectangle(grid.offset.x-5, grid.offset.y+(grid.height*grid.scale)/2, 10, grid.height*grid.scale,
            {
              label: 'wall',
              isStatic: true,
              collisionFilter: {
                group: 2,
                category: playerCategory,
                mask: playerCategory | itemCategory
              }
            }
          ))
          Matter.World.add(world,
            Matter.Bodies.rectangle(grid.offset.x+5+grid.width*grid.scale, grid.offset.y+(grid.height*grid.scale)/2, 10, grid.height*grid.scale,
            {
              label: 'wall',
              isStatic: true,
              collisionFilter: {
                group: 2,
                category: playerCategory,
                mask: playerCategory | itemCategory
              }
            }
          ))

          // Model Bleep Bloop
          bleepBloop.x = grid.offset.x+bleepBloop.gridX*grid.scale+grid.scale/2,
          bleepBloop.y = grid.offset.y+bleepBloop.gridY*grid.scale+grid.scale/2, 
          bleepBloop.body = Matter.Bodies.rectangle(
            bleepBloop.x,
            bleepBloop.y,
            grid.scale,
            grid.scale, 
            {
              label: 'bleepbloop',
              collisionFilter: {
                group: 2,
                category: playerCategory,
                mask: playerCategory | itemCategory
              },
              isStatic: true
            }
          )
          Matter.World.add(world, bleepBloop.body)
          // ------------ Add Cord ------------
          let group = Matter.Body.nextGroup(true)
          let cord = Matter.Composites.stack(400, 50, 13, 1, 10, 10, function(x, y) {
              return Matter.Bodies.rectangle(x - 20, y, 30, 10, { 
                label: 'cord',
                collisionFilter: { 
                  group: -12,
                  category: itemCategory,
                  mask: itemCategory | playerCategory
                }, 
                chamfer: 5 })
          })
          Matter.Composites.chain(cord, 0.3, 0, -0.3, 0, { stiffness: 1, length: 0 })
          Matter.Composite.add(cord, Matter.Constraint.create({ 
            bodyB: cord.bodies[0],
            pointB: { x: 0, y: 0 },
            pointA: { x: cord.bodies[0].position.x, y: cord.bodies[0].position.y },
            stiffness: 0.5
          }))
          Matter.Composite.add(cord, Matter.Constraint.create({ 
            bodyA: cord.bodies[cord.bodies.length-1],
            bodyB: bleepBloop.body,
            stiffness: 0.5
          }))
          Matter.Composite.add(cord, Matter.Constraint.create({ 
            bodyB: cord.bodies[0],
            pointB: { x: 0, y: 0 },
            pointA: { x: cord.bodies[0].position.x, y: cord.bodies[0].position.y },
            stiffness: 0.5
          }))
          // Matter.World.add(world, cord)

          // ------------ Create Runner ------------
          var runner = Matter.Runner.create()
          Matter.Runner.run(runner, engine)
        },

        p5.draw = _ => {
          p5.clear()
          p5.fill('white')
          p5.stroke('black')
          if (orientation == 'landscape') {
            p5.rect(primary.x,primary.y,primary.width,primary.height)
            p5.rect(secondary.x,secondary.y,secondary.width,secondary.height)
          } else {
            p5.stroke('green')
            p5.rect(primary.x,primary.y,primary.width,primary.height)
            p5.rect(secondary.x,secondary.y,secondary.width,secondary.height)
          }

          // ------------ Draw Background ------------
          // if (background == 1) p5.image(mk1['sketch1'], grid.offset.x, grid.offset.y, grid.width*grid.scale, grid.height*grid.scale)
          // if (background == 2) p5.image(mk1['sketch2'], grid.offset.x, grid.offset.y, grid.width*grid.scale, grid.height*grid.scale)
          // if (background == 3) p5.image(mk1['sketch3'], grid.offset.x, grid.offset.y, grid.width*grid.scale, grid.height*grid.scale)
          // if (background == 4) p5.image(mk1['sketch4'], grid.offset.x, grid.offset.y, grid.width*grid.scale, grid.height*grid.scale)
          p5.image(images['floor'], 0, 0, primary.width, primary.height)
          p5.image(images['wall'], 0, 0, primary.width, primary.height)
          p5.image(images['cabinet'], 0, 0, primary.width, primary.height)
          p5.image(images['workbench'], 0, 0, primary.width, primary.height)
          
          // ------------ Draw Grid ------------
          // p5.stroke('silver')
          // p5.noFill()
          // for (var y=0; y<grid.height; y++) {
          //   for (var x=0; x<grid.width; x++) {
          //     p5.rect( grid.offset.x+(grid.scale*x), grid.offset.y+(grid.scale*y), grid.scale, grid.scale)
          //   }
          // }

          // ------------ Draw Bleep Bloop ------------
          if (bleepBloop.moving) {
            if (bleepBloop.direction == 3) bleepBloop.x++
            if (bleepBloop.direction == 6) bleepBloop.y++
            if (bleepBloop.direction == 9) bleepBloop.x--
            if (bleepBloop.direction == 0) bleepBloop.y--
            bleepBloop.delta--
            if (bleepBloop.delta < 0) {
              bleepBloop.moving = false
              bleepBloop.x = grid.offset.x+bleepBloop.gridX*grid.scale+grid.scale/2
              bleepBloop.y = grid.offset.y+bleepBloop.gridY*grid.scale+grid.scale/2
            }
          
          Matter.Body.setPosition(bleepBloop.body, {x:bleepBloop.x, y:bleepBloop.y})}
          let imageScale = grid.scale / mk1['front'].width
          let bbw = mk1['front'].width * imageScale
          let bbh = mk1['front'].height * imageScale
          let bbx = bleepBloop.x - grid.scale/2 // grid.offset.x+(bleepBloop.gridX)*grid.scale
          let bby = bleepBloop.y + grid.scale/2 - mk1['front'].height/2 // grid.offset.y+(bleepBloop.gridY)*grid.scale-bbh+grid.scale
          if (bleepBloop.direction == 0) p5.image(mk1['back'], bbx, bby, bbw, bbh)
          if (bleepBloop.direction == 3) p5.image(mk1['right'], bbx, bby, bbw, bbh)
          if (bleepBloop.direction == 6) p5.image(mk1['front'], bbx, bby, bbw, bbh)
          if (bleepBloop.direction == 9) p5.image(mk1['left'], bbx, bby, bbw, bbh)
          // ------------ Draw Remote Control ------------
          p5.stroke('silver')
          let remote = {}
          remote.scale = secondary.width/secondary.height
          remote.width = secondary.width*.75
          remote.height = remote.width*1.33
          remote.x = secondary.x + (secondary.width - remote.width)/2
          remote.y = secondary.y + (secondary.height - remote.height)/2
          p5.rect(remote.x,remote.y,remote.width,remote.height)
          // Buttons
          p5.stroke('black')
          let button = grid.scale
          p5.fill('white')
          p5.keyIsDown(p5.UP_ARROW) ? p5.fill('silver') : p5.fill('white')
          p5.rect(remote.x+remote.width/2-button/2,remote.y+remote.height/2-button/2-button-padding,button,button)
          p5.keyIsDown(p5.DOWN_ARROW) ? p5.fill('silver') : p5.fill('white')
          p5.rect(remote.x+remote.width/2-button/2,remote.y+remote.height/2-button/2+button+padding,button,button)
          p5.keyIsDown(p5.LEFT_ARROW) ? p5.fill('silver') : p5.fill('white')
          p5.rect(remote.x+remote.width/2-button/2-button-padding,remote.y+remote.height/2-button/2,button,button)
          p5.keyIsDown(p5.RIGHT_ARROW) ? p5.fill('silver') : p5.fill('white')
          p5.rect(remote.x+remote.width/2-button/2+button+padding,remote.y+remote.height/2-button/2,button,button)

          // ------------ Draw Boxes ------------
          var bodies = Matter.Composite.allBodies(engine.world)
          for (var i = 0; i < bodies.length; i += 1) {
            let body = bodies[i]
            let label = body.label
            if ( label && label != 'ball' && label != 'cord') {
              if (label == 'box') {
                p5.push()
                p5.translate(body.position.x,body.position.y)
                p5.rotate(body.angle)
                p5.circle(0,0,10)
                p5.imageMode(p5.CENTER)
                p5.image(mk1['box'], 0, 0, grid.scale, grid.scale)
                p5.pop()
              }
              if (label == 'toy') {
                p5.push()
                p5.translate(body.position.x,body.position.y)
                p5.rotate(body.angle)
                p5.circle(0,0,10)
                p5.imageMode(p5.CENTER)
                p5.image(mk1['gobatron'], 0, 0, 12, 20)
                p5.pop()
              }
              if (label == 'bolt') {
                p5.push()
                p5.translate(body.position.x,body.position.y)
                p5.rotate(body.angle)
                p5.imageMode(p5.CENTER)
                p5.image(images['bolt'], 0, 0, 10, 10)
                p5.pop()
              }
              
            } else {
              var vertices = bodies[i].vertices
              for (var j=0; j < vertices.length; j++) {
                p5.stroke('red')
                if (j == 0) {
                  p5.line(vertices[0].x,vertices[0].y,vertices[vertices.length-1].x,vertices[vertices.length-1].y)
                } else {
                  p5.line(vertices[j-1].x,vertices[j-1].y,vertices[j].x,vertices[j].y)
                }
              }
            }
            p5.fill('white')
          }
          // 
          var bodies = Matter.Composite.allBodies(engine.world)
          for (var i = 0; i < bodies.length; i += 1) {
            let body = bodies[i]
            let label = body.label
            if (label == 'bucket') {
              p5.push()
              p5.translate(body.position.x,body.position.y)
              p5.rotate(body.angle)
              p5.imageMode(p5.CENTER)
              p5.image(images['bucket'], 0, 0, 40, 50)
              p5.pop()
            }
          }
          // ------------ Draw Map ------------
          let framerate = p5.frameCount / (new Date()/1000 - start/1000)
          p5.fill('grey')
          p5.text(Math.round(framerate,2), 10, 25)
          p5.noFill()
          // ------------ Draw Buffer ------------
          context.drawImage(p5.canvas, 0, 0, context.canvas.width, context.canvas.height)
        },

        p5.onResize = (event) => {

        }

        p5.keyPressed = (event) => {
          // p5.saveCanvas('layout.png')
          if (!bleepBloop.moving) {
            switch (event.code) {
              case 'ArrowRight':
                if (bleepBloop.direction == 3) {
                  if (bleepBloop.gridX < grid.width-1) {
                    bleepBloop.moving = true
                    bleepBloop.delta = grid.scale
                    bleepBloop.gridX++
                    Matter.Body.setVelocity(bleepBloop.body, {x:+10,y:0})
                  }
                } else {
                  bleepBloop.direction = 3
                }
                break;
              case 'ArrowLeft':
                if (bleepBloop.direction == 9) {
                  if (bleepBloop.gridX > 0) {
                    bleepBloop.moving = true
                    bleepBloop.delta = grid.scale
                    bleepBloop.gridX--
                    Matter.Body.setVelocity(bleepBloop.body, {x:-10,y:0})
                  }
                } else {
                  bleepBloop.direction = 9
                }
                break;
              case 'ArrowUp':
                if (bleepBloop.direction == 0) {
                  if (bleepBloop.gridY > 0) {
                    bleepBloop.moving = true
                    bleepBloop.delta = grid.scale
                    bleepBloop.gridY--
                    Matter.Body.setVelocity(bleepBloop.body, {x:0,y:-10})
                  }
                } else {
                  bleepBloop.direction = 0
                }
                break;
              case 'ArrowDown':
                if (bleepBloop.direction == 6) {
                  if (bleepBloop.gridY < grid.height-1) {
                    bleepBloop.moving = true
                    bleepBloop.delta = grid.scale
                    bleepBloop.gridY++
                    Matter.Body.setVelocity(bleepBloop.body, {x:0,y:10})
                  }
                } else {
                  bleepBloop.direction = 6
                }
                break;
              case 'Digit1':
                background = 1
                break;
              case 'Digit2':
                background = 2
                break;
              case 'Digit3':
                background = 3
                break;
              case 'Digit4':
                background = 4
                break;
              case 'Digit5':
                background = 5
                break;
              case 'KeyP':
                console.log('screenshot')
                p5.saveCanvas('bb.png')
                break;
              default:
                // console.log(event.code)
            }
          }
          // return false; // prevent default
        }

        p5.mouseClicked = (event) => {
          // let mx = Math.floor((event.offsetX - 20) / brickScale)
          // let my = Math.floor((event.offsetY - 20) / brickScale)
          // p5.draw()
        }

      }
      const P5 = require('p5') 
      new P5(script) // Using p5 as an instance mode
    },
  },
  watch: {
    context(newContext) {
      this.init(newContext)
    },
    "$vuetify.breakpoint.height" () {
      // p5.draw()
    },
    "$vuetify.breakpoint.width" () {
      // p5.draw()
    }
  }
}
</script>

<style>
  .p5Canvas {
    display: none;
    width: 2000px;
  }
</style>