<template>
  <div class="d-flex flex-row" style="height: 100%" ref="canvasContainer">
    <v-card flat tile :style="{ width: computedWidth + 'px' }" id="p5Canvas"></v-card>
    <div style="
      position: absolute;
      left: 960px;
      top: 0px;
      width: 480px;
      height: 100%;
      z-index: 1;
      background: #8187a1;
      overflow: auto">
      <img id="tiles" style="width: 480px" @click="tileClicked" src="/assets/sprites/base-tiles.png" />
    </div>
  </div>
</template>

<script>
// TODO: Painting tool, export part list
import p5 from 'p5'
import db from '@/firebase/firestore'
export default {
  name: 'Sandbox',
  data() {
    return {
      context: null,
      computedWidth: 0,
      computedHeight: 0,
      tileX: 2832/48,
      tileY: 2928/48,
    }
  },
  mounted() {
    // ----- Get window dimensions
    this.computedWidth = this.$refs.canvasContainer.clientWidth
    this.computedHeight = this.$refs.canvasContainer.clientHeight
    // ----- Update drawing context
    this.init(this.computedWidth, this.computedHeight)
  },
  methods: {
    tileClicked(event) {
      let a = Math.floor((960 - document.getElementById('tiles').x)/48)
      let b = Math.floor((event.screenX-960)/48)
      let x = a + b
      let y = Math.floor((event.clientY + Math.abs(document.getElementById('tiles').y))/48)
      let c = x + y * 10
      window.tile = c
      console.log( x, y, c )
    },
    init(width,height) {
      // ----- Initialize p5
      const script = function (p5) {
        var start
        var buffer

        var sandboxJson
        var floor
        var walls
        var floorSprites = []
        var floorSpriteSheet

        var grid
        var screen
        var camera
        var panel
        
        p5.preload = _ => {
          sandboxJson = p5.loadJSON('/assets/json/sandbox.json')
          floorSpriteSheet = p5.loadImage('/assets/sprites/base-tiles.png')
        }

        p5.setup = _ => {
          // ------------ Initialize Canvas ------------
          var canvas = p5.createCanvas(width, height)
          canvas.parent("p5Canvas")
          // ------------ Initialize Buffer ------------
          buffer = p5.createGraphics(width, height)
          // ------------ Configuration ------------
          p5.rectMode(p5.CENTER)
          p5.noStroke()
          // p5.noLoop()
          start = + new Date()
          p5.background('#333')
          // p5.frameRate(30)
          // ------------ Initialize Grid ------------
          grid = {
            width: 60, //100,
            height: 40, // 50,
            cell: { width: 48, height: 48 }
          }
          // ------------ Initialize Screen ------------
          screen = {
            x: 0,
            y: 0,
            width: p5.width-grid.cell.width*9,
            height: p5.height
          }
          // ------------ Initialize Panel ------------
          panel = {
            x: screen.width,
            y: screen.y,
            width: grid.cell.width*9,
            height: screen.height,
            tab: 1
          }
          // ------------ Initialize Camera ------------
          camera = {
            x: 0,
            y: 0,
            width: screen.width,
            height: screen.height
          }
          // ------------ Flooring ------------
          console.log(sandboxJson)
          console.log('setup', typeof sandboxJson, Object.keys(sandboxJson))
          floor = []
          for( var y=0; y<grid.height; y++) {
            floor[y] = []
            for( var x=0; x<grid.width; x++) {
              floor[y][x] = null
              if (sandboxJson.floor[y] && sandboxJson.floor[y][x] != null) {
                floor[y][x] = sandboxJson.floor[y][x]
              }
            }
          }
          // ------------ Flooring ------------
          // walls = sandboxJson.walls
          // ------------ Split Sprites ------------
          let c = 0
          for (var y=0; y<13; y++) {
            for (var x=0; x<10; x++) {
              let image = p5.createImage(48,48)
              image.copy(floorSpriteSheet,x*96,y*96,96,96, 0,0,48,48)
              floorSprites[floorSprites.length] = image
              c++
              // console.log('loaded: ' + Math.round((c/15)*100)) + '/'
              console.log('loaded: ' + c)
            }
          }
          // 
        }

        p5.draw = _ => {
          // ------------ Clear Screen ------------
          buffer.background('#263238')
          // ------------ Boundaries ------------
          let bounds = {
            lowerX: Math.floor(camera.x / grid.cell.width),
            lowerY: Math.floor(camera.y / grid.cell.height),
            upperX: Math.ceil((camera.x + camera.width)/grid.cell.width),
            upperY: Math.ceil((camera.y + camera.height)/grid.cell.height)
          }
          // ------------ Draw Grid ------------
          for (var y=bounds.lowerY; y<bounds.upperY; y++) {
            for ( var x=bounds.lowerX; x<bounds.upperX; x++) {
              let screenX = x * grid.cell.width
              let screenY = y * grid.cell.height
              // ------ Draw Floor ------
              if (y in floor && x in floor[y]) { // Valid 
                if (floor[y][x] == null) {
                  // ------ Draw Floor Sprite ------
                  buffer.stroke('grey')
                  buffer.noFill()
                  buffer.rect(
                    // screenX,
                    // screenY,
                    screenX-camera.x,
                    screenY-camera.y,
                    grid.cell.width,
                    grid.cell.height
                  )
                } else {
                  buffer.image(floorSprites[floor[y][x]],screenX-camera.x,screenY-camera.y,grid.cell.width,grid.cell.height)
                }
              } else {
                // Invalid tile
              }
            }
          }
          // ------------ Draw Camera ------------
          // buffer.stroke('magenta')
          // buffer.noFill()
          // buffer.rect(camera.x,camera.y,camera.width,camera.height)
          // ------------ Move Camera ------------
          if( p5.keyIsDown(p5.RIGHT_ARROW)) camera.x += 10
          if( p5.keyIsDown(p5.LEFT_ARROW)) camera.x -= 10
          if( p5.keyIsDown(p5.UP_ARROW)) camera.y -= 10
          if( p5.keyIsDown(p5.DOWN_ARROW)) camera.y += 10
          if( p5.keyIsDown(68)) camera.x += 10
          if( p5.keyIsDown(65)) camera.x -= 10
          if( p5.keyIsDown(87)) camera.y -= 10
          if( p5.keyIsDown(83)) camera.y += 10
          if ( camera.x < -48) camera.x = -48
          if ( camera.x > grid.width*grid.cell.width-screen.width+48) {
            camera.x = grid.width*grid.cell.width-screen.width+48
          }
          if ( camera.y < -48) camera.y = -48
          if ( camera.y > grid.height*grid.cell.height-screen.height+48) {
            camera.y = grid.height*grid.cell.height-screen.height+48
          }
          // if( p5.keyIsDown(68)) panel.camera.x += 10
          // if( p5.keyIsDown(65)) panel.camera.x -= 10
          // if( p5.keyIsDown(87)) panel.camera.y -= 10
          // if( p5.keyIsDown(83)) panel.camera.y += 10
          // ------------ Draw Panel ------------
          buffer.noStroke()
          buffer.fill('white')
          buffer.rect(panel.x,panel.y,panel.width,panel.height)
          // ------------ Render Back Buffer ------------
          p5.image(buffer, 0, 0, p5.width, p5.height)
          // ------------ Display Framerate ------------
          let framerate = p5.frameCount / (new Date()/1000 - start/1000)
          p5.stroke('grey')
          p5.fill('grey')
          p5.text(Math.round(framerate,2), 10, 25)
          // p5.saveCanvas('bowser-mario', 'png')
        }

        p5.mouseClicked = (event) => {
          let mx = Math.floor((event.clientX + camera.x)/48)
          let my = Math.floor((event.clientY + camera.y)/48)
          console.log(mx,my)
          if (event.clientX < p5.width - 480) {
            if (event.metaKey) {
              floor[my][mx] = null
            } else {
              floor[my][mx] = window.tile
            }
          }
        }

        p5.keyPressed = (event) => {
          switch (event.code) {
            case 'KeyJ':
              p5.saveJSON({floor:floor, walls:walls}, 'sandbox.json')
              break;
          }
        }
      
      
      }
      
      const P5 = require('p5') 
      new P5(script) // Using p5 as an instance mode
    },
  }
}
</script>

<style>
  .p5Canvas {
    display: block;
  }
</style>