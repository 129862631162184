<template>
  <div class="nav-container">
    <nav id="menu1" class="bar">
      <div class="container" style="display: flex; flex-direction: row; align-items: center;">
        <v-btn icon class="mr-2 visible-sm visible-xs" style="margin: 0"><v-icon>mdi-menu</v-icon></v-btn>
        <!-- Branding -->
        <div class="brand-container" @click="go('Landing')">
          <v-btn fab small elevation=0 dark color="#4a90e2" class="mr-2" style="margin: 0"><v-icon>mdi-code-tags</v-icon></v-btn>
          bleep bloop
        </div>
        <v-spacer />
        <!-- Menu -->
        <ul class="menu-horizontal mr-6 mb-0 hidden-sm hidden-xs">
          <li @click="go('Parents')">
            Parents
          </li>
          <li @click="go('Teachers')">
            Teachers
          </li>
        </ul>
        <!-- Buttons -->
        <span v-if="!user" @click="go('Login')" class="btn btn--sm type--uppercase mb-0" href="/demo">
          <span class="btn__text">Login</span>
        </span>
        <span v-else class="account-info mr-6" @click="go('Account')">
          <v-btn icon><v-icon>mdi-account</v-icon></v-btn> {{ user.profile.email }}
        </span>
        <span @click="go('Play')" class="btn btn--sm btn--primary type--uppercase mb-0 hidden-xs">
          <span class="btn__text">Play Game</span>
        </span>
      </div> <!-- End Container -->
    </nav>
  </div>
</template>
<script>
export default {
  name: 'Header',
  props: ['user'],
  data() {
    return {

    }
  }
}
</script>
<style lang="scss">
  .container {
    padding: 0 20px !important;
  }
  .brand-container {
    font-family: Lato, sans-serif;
    font-size: 26px;
    color: #4c4c4c;
    display: flex;
    text-decoration: none;
    transition: color 200ms ease-out;
    align-items: center;
  }
  .brand-container:hover {
    color: #a7a7a7;
    cursor: pointer;
  }
  .buttons {
    .btn__text {
      font-size: 12px;
    }
    display: flex;
    align-items: center;
  }
  .menu-horizontal {
    color: grey;
    transition: color 200ms ease-out;
    top: 0 !important;
  }
  .menu-horizontal li:hover {
    color: black;
    cursor: pointer;
  }
  .account-info:hover {
    color: grey;
    cursor: pointer;
  }
</style>