<template>
  <div class="creative engine disable-select">
    <!-- <div class="header">header content</div> -->
    <div class="main">
      <!-- ------------ Screen ------------ -->
      <div class="screen" ref="screen">
        <div class="overlay">
          <div class="top">top overlay: fps ({{ fps }})</div>
          <div class="middle">middle overlay</div>
          <div class="bottom">bottom overlay</div>
        </div>
        <div id="canvasContainer"></div>
      </div>
      <!-- ------------ Panel ------------ -->
      <div class="panel" ref="panel">
        <!-- <div class="overlay">overlay content</div> -->
        <!-- Selection -->
        <div class="selection">
          selected item
        </div>
        <!-- Robot Controls -->
        <div class="controls">
          <v-tabs class="tabs" color="blue">
            <v-tab>Remote</v-tab>
            <v-tab :disabled="true">Macro</v-tab>
            <v-tab :disabled="true">Events</v-tab>
            <v-tab :disabled="true">Bricks</v-tab>
            <v-tab :disabled="true">Code</v-tab>
            <v-tab-item class="control-panel">
              remote panel
            </v-tab-item>
            <v-tab-item class="control-panel">
              macro panel
            </v-tab-item>
            <v-tab-item class="control-panel">
              event panel
            </v-tab-item>
            <v-tab-item class="control-panel">
              fdsfd
            </v-tab-item>
            <v-tab-item class="control-panel">
              fdsfsdf
            </v-tab-item>
          </v-tabs>
        </div>
      </div>
    </div>
    <!-- <div class="footer">footer content</div> -->
  </div>
</template>

<script>
import db from '@/firebase/firestore'
import p5 from 'p5'
class Engine {
  constructor(vue, level) {
    this.vue = vue
    this.level = level
    this.init()
  }
  init() {
    const config = p5 => {
      var screenRef = null
      var panelRef = null
      var redraw = true
      var buffer = null
      var screen = null
      var camera = null
      var grid = null
      var layers = []
      var sprites = []
      var spritesheets = []
      var background = null
      var debug = true

      // =-=-=-=-=-=-=-=-=-=-=- PRELOAD -=-=-=-=-=-=-=-=-=-=-=
      p5.preload = _ => {
        if (debug) console.log('preloading...')
        // ------------ Extract Spritesheet Paths ------------
        for (var i=0; i<this.level.sprites.length; i++) {
          if (spritesheets[this.level.sprites[i].spritesheetIndex] == undefined) {
            spritesheets[this.level.sprites[i].spritesheetIndex] = p5.loadImage(this.level.sprites[i].path)
          }
        }
      }

      // =-=-=-=-=-=-=-=-=-=-=- SETUP -=-=-=-=-=-=-=-=-=-=-=
      p5.setup = _ => {
        if (debug) console.log('preload complete.')
        if (debug) console.log('setup commencing...')
        console.log('p5 level', this.level)
        screenRef = this.vue.$refs.screen
        screen = screenRef.getBoundingClientRect()
        grid = this.level.grid
        camera = {
          x: 0,
          y: 0,
          width: screen.width,
          height: screen.height,
        }
        // ------------ Recalculate Bounds ------------
        camera.bounds = {
          lowerX: Math.floor(camera.x / grid.cell.width),
          lowerY: Math.floor(camera.y / grid.cell.height),
          upperX: Math.ceil((camera.x + camera.width)/grid.cell.width),
          upperY: Math.ceil((camera.y + camera.height)/grid.cell.height)
        }
        var canvas = p5.createCanvas(screen.width,screen.height)
        canvas.parent("canvasContainer")
        // ------------ Initialize Sprites ------------
        for (var i=0; i<this.level.sprites.length; i++) {
          let sprite = p5.createImage(48,48)
          sprite.copy(
            spritesheets[this.level.sprites[i].spritesheetIndex], // Image
            this.level.sprites[i].x*48, this.level.sprites[i].y*48, 48, 48, // Source
            0, 0, 48, 48) // Destination
          sprites.push(sprite)
          // console.log('loaded sprite', i+1, 'of', this.level.sprites.length)
        }
        // ------------ Count Layers ------------
        // TODO: Load layers variable from grid object in firebase
        grid.layers = 0
        for (var i=0; i<this.level.tiles.length; i++) {
          if (this.level.tiles[i].layer > grid.layers) grid.layers = this.level.tiles[i].layer
        }
        console.log('layers', grid.layers)
        // ------------ Initiate Layers ------------
        for (var layer=0; layer<=grid.layers; layer++) {
          layers[layer] = []
          for (var row=0; row<grid.height; row++) {
            layers[layer][row] = []
            for (var col=0; col<grid.width; col++) {
              layers[layer][row][col] = null
            }
          }
        }
        for (var i=0; i<this.level.tiles.length; i++) {
          let tile = this.level.tiles[i]
          // if (layers[tile.layer] == undefined) {
            // console.log('undefined', i)
          // } else {
          layers[tile.layer][tile.y][tile.x] = tile.sprite
          // }
        }
        // ------------ 
        if (debug) console.log('setup complete.')
      }

      // =-=-=-=-=-=-=-=-=-=-=- DRAW -=-=-=-=-=-=-=-=-=-=-=
      p5.draw = _ => {
        let frameStart = new Date()
        if (redraw) {
          if (background) {
            p5.image(background,-camera.x,-camera.y)
          } else {
            p5.clear()
          }
          for (var layer=0; layer<=grid.layers; layer++) {
            for (var row=camera.bounds.lowerY; row<camera.bounds.upperY; row++) {
              for ( var col=camera.bounds.lowerX; col<camera.bounds.upperX; col++) {
                let screenX = col * grid.cell.width
                let screenY = row * grid.cell.height
                if (layers[layer][row][col] != null) {
                  let index = layers[layer][row][col]
                  // ------------ Render Sprite ------------
                  if (sprites[index] != undefined) {
                    // p5.fill('red')
                    // p5.rect(
                    //   screenX-camera.x,
                    //   screenY-camera.y,
                    //   grid.cell.width,
                    //   grid.cell.height
                    // )
                    p5.image(
                      sprites[index],
                      screenX-camera.x,
                      screenY-camera.y,
                      grid.cell.width,
                      grid.cell.height
                    )
                  } else {
                    // console.log('Error: could not find sprite')
                  }
                }
                // ------ Draw Grid ------
                if (debug) {
                  p5.stroke('grey')
                  p5.noFill()
                  p5.rect(
                    screenX-camera.x,
                    screenY-camera.y,
                    grid.cell.width,
                    grid.cell.height
                  )
                  p5.stroke('black')
                }
              } // column
            } // row
          } // layer
          redraw = false
        }
        // ------------ Keyboard Input ------------
        if( p5.keyIsDown(p5.RIGHT_ARROW) || p5.keyIsDown(68) ) p5.moveCamera('right')
        if( p5.keyIsDown(p5.LEFT_ARROW)  || p5.keyIsDown(65) ) p5.moveCamera('left')
        if( p5.keyIsDown(p5.UP_ARROW)    || p5.keyIsDown(87) ) p5.moveCamera('up')
        if( p5.keyIsDown(p5.DOWN_ARROW)  || p5.keyIsDown(83) ) p5.moveCamera('down')
        // ------------ Calulate FPS ------------
        this.vue.framerate(new Date() - frameStart)
      }

      p5.moveCamera = (direction) => {
        // ------------ Change Direction ----------
        // Benchmarked for performance
        if (direction == 'up') camera.y -= 10;
        else if (direction == 'down') camera.y += 10;
        else if (direction == 'left') camera.x -= 10;
        else if (direction == 'right') camera.x += 10;
        // ------------ Impose Boundaries ------------
        if ( camera.x > grid.width*grid.cell.width-screen.width) {
          camera.x = grid.width*grid.cell.width-screen.width
        }
        if ( camera.x < 0) camera.x = 0
        if ( camera.y > grid.height*grid.cell.height-screen.height) {
          camera.y = grid.height*grid.cell.height-screen.height
        }
        if ( camera.y < 0) camera.y = 0
        // ------------ Recalculate Bounds ------------
        camera.bounds = {
          lowerX: Math.floor(camera.x / grid.cell.width),
          lowerY: Math.floor(camera.y / grid.cell.height),
          upperX: Math.ceil((camera.x + camera.width)/grid.cell.width),
          upperY: Math.ceil((camera.y + camera.height)/grid.cell.height)
        }
        // ------------ Initiate Redraw ------------
        redraw = true
      }

      // =-=-=-=-=-=-=-=-=-=-=- Event: Window Resized -=-=-=-=-=-=-=-=-=-=-=
      p5.windowResized = _ => {
        let width = 2 * Math.floor(window.innerWidth / 3)
        let height = Math.floor(window.innerHeight)
        camera.width = width
        camera.height = height
        p5.moveCamera() // Recalculates camera bounds
        p5.resizeCanvas(width, height)
        redraw = true
      }

      // =-=-=-=-=-=-=-=-=-=-=- Event: Mouse Moved -=-=-=-=-=-=-=-=-=-=-=
      p5.mouseMoved = (event) => {
        
      }
      
      // =-=-=-=-=-=-=-=-=-=-=- Event: Mouse Clicked -=-=-=-=-=-=-=-=-=-=-=
      p5.mouseClicked = (event) => {
      }

      // =-=-=-=-=-=-=-=-=-=-=- Event: Key Pressed -=-=-=-=-=-=-=-=-=-=-=
      p5.keyPressed = (event) => {
      }
    }
    this.p5 = new p5(config)
  }
}

class Creative extends Engine {
  constructor(vue, level) {
    super(vue, level)
  }
}
export default {
  name: 'Creative',
  data() {
    return {
      fps: 0,
      min: null,
      max: null,
      engine: null,
      readings: 0,
      sum: 0,
    }
  },
  mounted() {
    // ------------ Garbage Collection ------------
    let canvases = document.getElementsByClassName('p5Canvas')
    if (canvases.length > 1) window.location.reload()
    // ------------ Load Level Data ------------
    db.collection('levels').doc('sihixsppuRu9mGgUPuiT').get().then( doc => {
      if (doc.exists) {
        // ------------ Extract Level Data ------------
        let level = doc.data()
        level.id = doc.id
        // ------------ Instantiate Engine ------------
        this.engine = new Creative(this, level)
      }
    })
  },
  methods: {
    framerate( delta ) {
      if (this.max == null) this.max = delta
      if (this.min == null && delta) this.min = delta
      if (delta < this.min && delta) this.min = delta
      if (delta > this.max) this.max = delta
      if (delta) {
        this.readings++
        this.sum+= 100/delta
      }
      let average = this.sum/this.readings
      this.fps = delta + 'ms refresh, ' + (100/delta).toFixed(1) + " fps, (min: " + this.min + ', max: ' + this.max + ', average: ' + average.toFixed(1) + ')'
    }
  }

}
</script>

<style lang="scss">
  $lightgrey: #efefef;
  $darkgrey: #ccc;
  $sand: #99826b;
  $slate: #99826b;
  .engine {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: 'roboto';
    .header {
      border-bottom: 1px solid $darkgrey;
      padding: 5px;
    }
    .main {
      display: flex;
      flex: 1;
      flex-direction: row;
      .screen {
        display: flex;
        flex: 2;
        flex-direction: column;
        position: relative;
        .overlay {
          display: flex;
          flex-direction: column;
          height: 100%;
          position: absolute;
          width: 100%;
          .top {
            color: white;
            font-weight: bold;
          }
          .middle {
            flex: 1;
            display: flex;
            align-items: center;
          }
          .bottom {

          }
        }
      }
      .panel {
        border-left: 1px solid $darkgrey;
        display: flex;
        flex: 1;
        flex-direction: column;
        max-width: 500px;
        position: relative;
        .selection {
          background: $lightgrey;
          flex: 1;
        }
        .controls {
          flex: 4;
          .tabs {
            height: 100%;
            display: flex;
            flex-direction: column;
            .v-tabs-items {
              flex: 1;
              .v-window__container {
                height: 100%;
              }
            }
          }
        }
        .control-panel {
          background: $lightgrey;
          height: 100%;
        }
      }
      canvas {
        width: 100%;
        height: 100%;
      }
    }
    .footer {
      border-top: 1px solid $darkgrey;
      padding: 5px;
    }
  }
  .disable-select {
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
  }
</style>