<template>
  <div class="landing">
    <!-- Hero -->
    <section class="switchable bg--primary">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-5 col-md-7">
            <div class="mt--2">
              <h1>Complete system for teaching kids to code</h1>
              <p class="lead">Backed by science, approved by kids</p>
              <span class="btn btn--primary type--uppercase" @click="go('Play')">
                <span class="btn__text">Launch The Game</span>
              </span>
              <span class="block type--fine-print">
                It's completely free, no credit card required. Seriously.
              </span>
            </div>
          </div>
          <div class="col-lg-7 col-md-5 col-12 d-flex">
            <div class="image-container">
              <!-- <v-img alt="Image" src="/assets/img/bleep-bloop-right.png" /> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Features for Parents -->
    <!--
      Progressive: From simple sequences to written code. Bleep Bloop guides your child 
      Challenges: Gently introduces challenges for your child to problem solve using newly learned techniques
      Subtle: Learning to think in algorithms. 
    -->
    <!-- Features for Kids -->
    <!--
      Fun: 
      Customizable: 
      Persistent: Build 
      Creative: Design your factory an
    -->

    <!-- Hero -->
    <!-- <section class="text-center">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="typed-headline">
              <span class="h1 inline-block">We help <span class="color--primary">conversational</span> artificial intelligence</span>
            </div>
            <p class="lead">
              A Complete System for Teaching Kids to Code
            </p>
            <a class="btn btn--primary type--uppercase inner-link" href="/get-started">
              <span class="btn__text">
                Get Started
              </span>
            </a>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Voice Assistant (forward facing) -->
    <!-- <section class="cover bg--secondary switchable switchable--switch">
      <div class="container">
        <div class="row justify-content-around">
          <div class="col-md-6 text-center">
            <div>
              <h6 class="type--uppercase">Voice Assistant Apps</h6>
              <h1>Hey Google,</h1>
              <span class="h2 inline-block typed-text typed-text--cursor color--primary" data-typed-strings="what is my account balance?,when is my bill due?,are there any outages?,reorder more Cool Product">b</span>
              <p class="lead">
                Learn how your business can interact with customers through their favourite voice assistant
                <ul class="social-list list-inline list--hover">
                  <li><i class="socicon socicon-google icon icon--xs"></i></li>
                  <li><i class="socicon socicon-amazon icon icon--xs"></i></li>
                  <li><i class="socicon socicon-apple icon icon--xs"></i></li>
                  <li><i class="socicon socicon-windows icon icon--xs"></i></li>
                </ul>
              </p>
              <a class="btn btn--primary type--uppercase" href="/demo">
                <span class="btn__text">
                  Try Demo
                </span>
              </a>
              <span class="block type--fine-print">
                or you can <a href="/solutions/assistants">learn more about voice assistants</a>
              </span>
            </div>
          </div>
          <div class="col-md-6 col-lg-5 mt--2">
            <img alt="Image" src="img/googlehome2.png">
          </div>
        </div>
      </div>
    </section> -->

    <!-- Limitless Possibilities -->
    <!-- <section class="text-center">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
            <h2>Limitless Possibilities.</h2>
            <p class="lead">
              Now is the time to embrace the emerging technologies that allow real-time communication with artificial intelligence to be possible.<br>Let us help you realize its potential for your business.
            </p>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Types -->
    <!-- <section class="text-center">
      <div class="tabs-container" data-content-align="left">
        <ul class="tabs tabs--spaced">
          <li class="active">
            <div class="tab__title text-center">
              <i class="icon icon--sm block icon-Speak-2"></i>
              <span class="h5"><p>Voice Assistants</p></span>
            </div>
            <div class="tab__content">
              <div class="container switchable switchable--switch">
                <div class="row">
                  <div class="col-md-7">
                    <img alt="Image" src="/assets/img/device-echo.png">
                  </div>
                  <div class="col-md-5 col-lg-4">
                    <div class="mt--1">
                      <h3>Voice Assistants</h3>
                      <p class="lead">A natural way for your customers to interact with your business.</p>
                      <hr class="short">
                      <p>Provide your customers with the simplicity of a conversation and the immediate response they demand. All in the comfort of their own home.</p>
                      <a class="btn btn--primary btn--sm type--uppercase" href="/solutions/assistants">
                        <span class="btn__text">Learn More</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="tab__title text-center">
              <i class="icon icon--sm block icon-Consulting"></i>
              <span class="h5"><p>Artificial Agents</p></span>
            </div>
            <div class="tab__content">
              <div class="container switchable switchable--switch">
                <div class="row">
                  <div class="col-md-7">
                    <img alt="Image" src="/assets/img/device-echo.png">
                  </div>
                  <div class="col-md-5 col-lg-4">
                    <div class="mt--1">
                      <h3>Artificial Agents</h3>
                      <p class="lead">Dramatically lower your support costs and improve your user experience</p>
                      <hr class="short">
                      <p>Once our AI learns your business logic it can be immediately deployed to interact with your customers with infinite scalability. Your customers never need to be put on hold again.</p>
                      <a class="btn btn--primary btn--sm type--uppercase" href="/solutions/agents">
                        <span class="btn__text">Learn More</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="tab__title text-center">
              <i class="icon icon--sm block icon-CPU"></i>
              <span class="h5"><p>Automation</p></span>
            </div>
            <div class="tab__content">
              <div class="container switchable switchable--switch">
                <div class="row">
                  <div class="col-md-7">
                    <img alt="Image" src="/assets/img/device-echo.png">
                  </div>
                  <div class="col-md-5 col-lg-4">
                    <div class="mt--1">
                      <h3>Automation</h3>
                      <p class="lead">Reduce complex tasks into simple conversations</p>
                      <hr class="short">
                      <p>People approach problems in different ways. Allow your staff to interact with your systems in a way which is natural to them, while saving training costs and boosting productivity.</p>
                      <a class="btn btn--primary btn--sm type--uppercase" href="/solutions/automation">
                        <span class="btn__text">Learn More</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="tab__title text-center">
              <i class="icon icon--sm block icon-Love-User"></i>
              <span class="h5"><p>Voice Experience</p></span>
            </div>
            <div class="tab__content">
              <div class="container switchable switchable--switch">
                <div class="row">
                  <div class="col-md-7">
                    <img alt="Image" src="/assets/img/device-echo.png">
                  </div>
                  <div class="col-md-5 col-lg-4">
                    <div class="mt--1">
                      <h3>Voice Experience</h3>
                      <p class="lead">Speak directly to your audience.</p>
                      <hr class="short">
                      <p>Create an immersive experience which allows your audience to interact directly with you. It's fun, personalized, engaging, and may be monetized for an additional revenue stream.</p>
                      <a class="btn btn--primary btn--sm type--uppercase" href="/solutions/experience">
                        <span class="btn__text">Learn More</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section> -->

    <!-- Call to Action -->
    <!-- <section class="text-center imagebg" data-gradient-bg="#4876BD,#5448BD,#8F48BD,#BD48B1">
      <canvas id="granim-0" width="814" height="339"></canvas>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-lg-6">
            <div class="cta">
              <h2>Over {{ conversations }} virtual conversations</h2>
              <p class="lead">
                Our technology is already being used by over 4000 users each day. We are passionate about conversational AI and have been recognized as a leader in the field by Google.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Features -->
    <!-- <section class="switchable feature-large">
      <div class="container">
        <div class="row justify-content-around">
          <div class="col-lg-5 col-md-6 switchable__text">
            <h2>Advanced Functionality</h2>
            <p class="lead">
              Voice assistant platforms provide many features which may be implemented into your application.
            </p>
            <hr class="short">
            <p>Provide your customers with the simplicity of a conversation and the immediate response they demand. All in the comfort of their own home.</p>
            <p>Advanced functionality provides unqiue features only available through a voice interface.</p>
          </div>
          <div class="col-lg-4 col-md-6 col-lg-pull-1 col-12">
            <div class="boxed boxed--lg boxed--border box-shadow-wide">
              <div class="slider" data-paging="true">
                <ul class="slides">
                  <li class="col-12">
                    <div class="feature feature-3 text-center">
                      <i class="icon icon--lg icon-Sound-Wave color--primary"></i>
                      <h4>Hear Your Users</h4>
                      <p>Now you can literally listen to exactly what your users are asking for - not just what they complain about.</p>
                    </div>
                  </li>
                  <li class="col-12">
                    <div class="feature feature-3 text-center">
                      <i class="icon icon--lg icon-Lock color--primary"></i>
                      <h4>Authentication</h4>
                      <p>Allow access to your users' data by securely authenticating to your own servers.</p>
                    </div>
                  </li>
                  <li class="col-12">
                    <div class="feature feature-3 text-center">
                      <i class="icon icon--lg icon-Fingerprint color--primary"></i>
                      <h4>Voice Imprinting</h4>
                      <p>Advanced voice recognition technology allows your users to authenticate or differentiate with just their voice.</p>
                    </div>
                  </li>
                  <li class="col-12">
                    <div class="feature feature-3 text-center">
                      <i class="icon icon--lg icon-Coins color--primary"></i>
                      <h4>Payments</h4>
                      <p>Your users can make a payment or setup a subscription for your content or service using their linked Google or Amazon accounts.</p>
                    </div>
                  </li>
                  <li class="col-12">
                    <div class="feature feature-3 text-center">
                      <i class="icon icon--lg icon-Line-Chart4 color--primary"></i>
                      <h4>Metrics</h4>
                      <p>See exactly how your customers want to interact with your business and track engagement with our monthly reports.</p>
                    </div>
                  </li>
                  <li class="col-12">
                    <div class="feature feature-3 text-center">
                      <i class="icon icon--lg icon-DNA-2 color--primary"></i>
                      <h4>Adaptive</h4>
                      <p>Learns with every conversation to become more effective without needing additional resources.</p>
                    </div>
                  </li>
                  <li class="col-12">
                    <div class="feature feature-3 text-center">
                      <i class="icon icon--lg icon-Tactic color--primary"></i>
                      <h4>Dynamic</h4>
                      <p>Your users will be able to converse naturally as our technology adapts in real-time to changing conversation dynamics.</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Internal Applications -->
    <!-- <section class="bg--primary-1 text-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-9 col-12">
            <div class="mt--0">
              <img alt="headline" src="img/headline.png" />
                <br>
              <p class="lead">
                Not just a replacement for contact forms, chatbots, and support agents.
                <br class="hidden-sm hidden-xs"> 
                Give your users an entirely new and engaging experience that adapts to their needs in real-time. The next level is already here.
              </p>
              <a class="btn btn--primary type--uppercase" href="/get-started">
                <span class="btn__text">Get Started</span>
              </a>
              <span class="block type--fine-print">or
                <a href="/demo">view the demo</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Mr. Grumbles -->
    <!-- <section class="text-center cta cta-4 space--xxs border--bottom imagebg" data-gradient-bg="#4876BD,#5448BD,#8F48BD,#BD48B1">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <span class="label label--inline">NEW!</span> <span>Mr. Grumbles hits #1 trending on the Google Assistant app store!</span>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Showcase -->
    <!-- <section class="text-center space--sm">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-lg-8">
            <h2>Case Studies</h2>
            <p class="lead">Explore some featured applications for our amazing clients</p>
          </div>
        </div>
      </div>
    </section> -->

    <!-- <section>
      <div class="container">
        <div class="masonry">
          <div class="masonry__container row masonry--active">
            <div class="col-md-4 masonry__item">
              <div class="card card-2 text-center">
                <div class="card__top">
                  <span class="label">New</span>
                  <img alt="Image" src="img/mrgrumbles-wip.png">
                </div>
                <div class="card__body">
                  <h4>Mr. Grumbles</h4>
                  <span class="type--fade">Voice Experience</span>
                  <p>Having over 100,000 conversations per month has propelled Mr. Grumbles to the #1 trending voice assistant across all categories.</p>
                </div>
                <div class="card__bottom text-center">
                  <div class="card__action">
                    <span class="h6 type--uppercase">Explore</span>
                    <a href="https://assistant.google.com/services/a/uid/00000081add75baa?hl=en-US"> <i class="material-icons">flip_to_front</i> </a>
                  </div>
                  <div class="card__action">
                    <span class="h6 type--uppercase">4.2</span>
                    <i class="material-icons">star_border</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 masonry__item">
              <div class="card card-2 text-center">
                <div class="card__top">
                  <img alt="Image" src="img/nextutilities2.png">
                </div>
                <div class="card__body">
                  <h4>Next Utilities</h4>
                  <span class="type--fade">Voice Assistant</span>
                  <p>Allows customers to manage their accounts through natural voice conversations. Reducing the costs of operating a call centre.</p>
                </div>
                <div class="card__bottom text-center">
                  <div class="card__action">
                    <span class="h6 type--uppercase">Explore</span>
                    <i class="material-icons">flip_to_front</i>
                  </div>
                  <div class="card__action">
                    <span class="h6 type--uppercase">n/a</span>
                    <i class="material-icons">star_border</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 masonry__item">
              <div class="card card-2 text-center">
                <div class="card__top">
                  <img alt="Image" src="img/infoanywhere.png">
                </div>
                <div class="card__body">
                  <h4>Info Anywhere</h4>
                  <span class="type--fade">Medical Assistant</span>
                  <p>Our bot allows staff to record and resolve complex scheduling using simple language - reducing mistakes and increasing efficiency</p>
                </div>
                <div class="card__bottom text-center">
                  <div class="card__action">
                    <span class="h6 type--uppercase">Explore</span>
                    <i class="material-icons">flip_to_front</i>
                  </div>
                  <div class="card__action">
                    <span class="h6 type--uppercase">5.0</span>
                    <i class="material-icons">star_border</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Last Call to Action: "Let's Talk" -->
    <!-- <section class="text-center imagebg" data-overlay="4">
      <div class="background-image-holder"> <img alt="background" src="img/about-8blue.png"> </div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-lg-6">
            <div class="cta">
              <h2>Be an Innovation Leader</h2>
              <p class="lead">
                Now is the time for your business to stay ahead of this exciting new technology as it trends towards ubiquity. We can help you carve your own path to save money and delight your customers.
              </p>
              <a class="btn btn--primary btn--lg type--uppercase" href="/get-started">
                <span class="btn__text">Get Started</span>
              </a>
              <p class="type--fine-print">No credit card required. It is free.</p>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Alpha Testers -->
    <section class="text-center bg--secondary">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-lg-6">
            <div class="cta">
              <h2>Alpha Testers Wanted</h2>
              <p>Would you like to try out Bleep Bloop before launch? Join the alpha testing team we would love to hear your feedback</p>
              <input disabled="true" type="email" name="email" placeholder="Email Address">
              <a class="btn btn--primary type--uppercase mt-4" href="#purchase-template">
                <span class="btn__text">
                  Join Alpha Testers
                </span>
                <span class="label">Closed</span> 
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import '@/assets/js/jquery-3.1.1.min.js'
// import '@/assets/js/flickity.min.js'
// import '@/assets/js/easypiechart.min.js'
// import '@/assets/js/parallax.js'
// import '@/assets/js/typed.min.js'
// import '@/assets/js/datepicker.js'
// import '@/assets/js/ytplayer.min.js'
// import '@/assets/js/lightbox.min.js'
// import '@/assets/js/granim.min.js'
// import '@/assets/js/isotope.min.js'
// import '@/assets/js/jquery.steps.min.js'
// import '@/assets/js/countdown.min.js'
// import '@/assets/js/twitterfetcher.min.js'
// import '@/assets/js/spectragram.min.js'
// import '@/assets/js/smooth-scroll.min.js'
// import '@/assets/js/scripts.js'
export default {
  name: 'Landing',
  data() {
    return {
      
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/app.scss';
  @import '@/assets/scss/stack-interface.scss';
  @import '@/assets/scss/socicon.scss';
  @import '@/assets/scss/lightbox.min.scss';
  @import '@/assets/scss/bootstrap.scss';
  @import '@/assets/scss/flickity.scss';
  @import '@/assets/scss/stack-interface.scss';
  @import '@/assets/scss/jquery.steps.scss';
  @import '@/assets/scss/theme.scss';
  @import '@/assets/scss/custom.scss';
  .image-container {
    display: flex;
    flex: 1;
    background-image: url(/assets/img/bleep-bloop-right.png);
    background-size: contain;
    background-position: center;
  }
</style>