<template>
  <div class="d-flex flex-row" style="height: 100%" ref="canvasContainer">
    <v-card flat tile id="p5Canvas">
      <canvas ref="canvas" id="canvas"></canvas>
      <Level1 :context="context"></Level1>
    </v-card>
  </div>
</template>
<script>
import Level1 from '../levels/Level1'
export default {
  name: 'Play',
  components: { Level1 },
  data() {
    return {
      canvas: null,
      context: null
    }
  },
  mounted() {
    this.resize()
  },
  methods: {
    resize() {
      // ------ Get Canvas Dimensions ------
      this.$refs.canvas.width = this.$vuetify.breakpoint.width
      this.$refs.canvas.height = this.$vuetify.breakpoint.height
      // ------ Get Context Dimensions ------
      let context = this.$refs.canvas.getContext("2d")
      context.width = this.$refs.canvas.width
      context.height = this.$refs.canvas.height
      // ------ Set Context ------
      this.context = context
    }
  }
}
</script>