<template>
  <div class="container">
    <h4>Account</h4>
    <v-alert v-if="user && !user.approved" icon="mdi-mail" prominent text type="warning">
      Your account is not verified. Please check your email for the activation link.
    </v-alert>
    <v-list>
      <v-list-item @click="logout()">Logout</v-list-item>
    </v-list>
  </div>
</template>
<script>
import firebase from 'firebase'
export default {
  name: 'Account',
  props: ['user'],
  methods: {
    logout() {
      firebase.auth().signOut()
    }
  }
}
</script>