<template>
  <v-app>
    <!-- <Header v-if="!fullscreen" :user="user" /> -->
    <!-- <v-main> -->
      <router-view :user="user" />
    <!-- </v-main> -->
    <Footer v-if="!fullscreen" />
  </v-app>
</template>

<script>
import firebase from 'firebase'
import db from '@/firebase/firestore'
import Header from './components/Header'
import Footer from './components/Footer'
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data: () => ({
    user: null,
    route: '',
    fullscreen: false
  }),
  created() {
    this.route = this.$route.name
    this.fullscreen = this.$route.meta.fullscreen
    // ------------ Auth Listener ------------
    firebase.auth().onAuthStateChanged( firebaseUser => {
      if (firebaseUser) {
        // ------------ Firestore User Listener ------------
        db.collection('users').doc(firebaseUser.uid).onSnapshot( snapshot => {
          this.user = snapshot.data()
        })
        // ------------ Handle User Authentication ------------
        db.collection('users').doc(firebaseUser.uid).get().then( doc => {
          let data = doc.data()
          if (doc.exists) {
            // ------------ Login ------------
            this.user = doc.data()
            if (this.route == 'Login') this.go('Play')
          } else {
            // ------------ Registration ------------
            let user = {
              uid: firebaseUser.uid,
              profile: {
                name: firebaseUser.displayName,
                email: firebaseUser.email,
                photo: firebaseUser.photoURL,
                avatar: firebaseUser.photoURL,
                phone: firebaseUser.phoneNumber
              },
              verified: firebaseUser.emailVerified,
              approved: false
            }
            db.collection('users').doc(firebaseUser.uid).set(user).then( result => {
              this.log("User Registered")
              this.user = user
              this.go('Play')
            }).catch( err => {
              this.log("User Registration Failed.")
              this.user = null
            })
          }
        })
      } else {
        // ------------ Logged Out ------------
        if (this.user) {
          // console.log('User Logged Out')
          this.user = null
          this.go('Login')
        }
      }
    })
  },
  watch: {
    $route() {
      this.route = this.$route.name
      this.fullscreen = this.$route.meta.fullscreen
    }
  }
};
</script>
<style>
  .container, .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding: 0;
  }
  /* @media (max-width: 960px) {
    .container {
      margin: 0 20px;
    }
  } */
</style>