<template>
  <div class="container">
    <v-card>
      <v-card-title>Select Character</v-card-title>
      <v-card-text>
        <img src="/assets/img/avatar.png" style="width: 20%"/>
        <!-- <div id="p5Canvas" class="mb-4"></div> -->
        <v-alert v-if="user && user.admin" icon="mdi-shield-lock-outline" prominent text type="info">
          You are an administrator
        </v-alert>
        <div v-if="user && user.approved">
          <v-btn dark block color="blue" @click="go('Story')" class="mb-2">Story Mode</v-btn>
          <v-btn block class="mb-2" @click="go('Creative')">Creative Mode</v-btn>
        </div>
        <div v-if="user && !user.approved">
          <v-btn :disabled="true" block class="mb-2">Story Mode</v-btn>
          <v-btn :disabled="true" block>Creative Mode</v-btn>
        </div>
        <div v-if="user && user.admin">
          <hr class="mt-6">
          <v-btn block class="mb-2 mt-6" @click="go('Sandbox')">Sandbox</v-btn>
          <v-btn block class="mb-2" @click="go('Editor')">Editor</v-btn>
          <v-btn block class="mb-2" @click="go('Prototype')">Prototype</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import p5 from 'p5'
export default {
  name: 'Play',
  props: ['user'],
  mounted() {
    // const script = function (p5) {
    //   var characters = []
    //   var selected = 0
    //   p5.preload = _ => {
    //     for(var i=0; i<7; i++) {
    //       characters[i] = p5.loadImage('/assets/sprites/kid' + (i+1) + '.png')
    //     }
    //   }
    //   p5.setup = _ => {
    //     // ------------ Initialize Canvas ------------
    //     var canvas = p5.createCanvas(96,96)
    //     canvas.parent("p5Canvas")
    //     // ------------ Initialize Buffer ------------
    //     p5.noLoop()
    //   }
    //   p5.draw = _ => {
    //     p5.background('#263238')
    //     p5.copy(characters[selected],150,20, 60,96, 28,0,60,96)
    //   }
    //   p5.mouseClicked = (event) => {
    //     if (event.target.id == 'defaultCanvas0') {
    //       selected++
    //       if (selected > 6) selected = 0
    //       p5.redraw()
    //     }
    //   }
    // }  
    // const P5 = require('p5') 
    // new P5(script) // Using p5 as an instance mode
  }
}
</script>
<style>
  .p5Canvas {
    display: block !important;
  }
</style>