<template>
  <div class="login">
    <section style="padding: 3.5em 0 1em 0;">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2>Login</h2>
            <hr>
          </div>
        </div>
      </div>
    </section>
    <v-container fluid pa-6>
      <v-card class="start-card" flat>
        <!-- <v-img
          class="white--text align-end"
          height="200px"
          src="https://webdesignledger.com/wp-content/uploads/2010/01/nature_photography_3.jpg"
        >
          <v-card-title>Let's Do This.</v-card-title>
        </v-img> -->
        <v-card-text class="mt-4">
          <v-alert type="error" v-if="socialFeedback">
            {{ socialFeedback }}
          </v-alert>
          <v-btn
            color="red darken-3"
            class="white--text mb-2"
            @click="googleStart()"
            block
          >
            <v-icon left dark>mdi-google</v-icon> Google Account
          </v-btn>
          <v-btn
            color="blue darken-3"
            class="white--text mb-2"
            @click="facebookStart()"
            block
            :disabled="true"
          >
            <v-icon left dark>mdi-facebook</v-icon> Facebook Account
          </v-btn>
          <v-btn
            color="light-blue darken-2"
            class="white--text mb-2"
            @click="twitterStart()"
            block
            :disabled="true"
          >
            <v-icon left dark>mdi-twitter</v-icon> Twitter Account
          </v-btn>
        </v-card-text>
        <v-overlay :value="overlay" :absolute="true" z-index="2">
          <v-card :color="overlayStyle" dark width="300">
            <v-card-text>
              {{ overlayText }}
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0 mt-4"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-overlay>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import firebase from 'firebase'
import db from '@/firebase/firestore'
export default {
  name: 'Login',
  data() {
    return {
      socialFeedback: '',
      overlay: false,
      overlayStyle: 'primary',
      overlayText: 'Authenticating, one moment please :)'
    }
  },
  methods: {
    go( route ) {
      this.$router.push({name: route})
    },
    googleStart() {
      this.overlayStyle = 'red darken-3'
      this.overlay = true
      this.showEmailPasswordForm = false
      this.socialFeedback = ''
      let provider = new firebase.auth.GoogleAuthProvider()
      // provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
      firebase.auth().languageCode = 'en'
      firebase.auth().signInWithPopup(provider)
      .then( credential => {
        if (credential.additionalUserInfo.isNewUser) {
          db.collection('users').add({
            uid: credential.user.uid,
            email: credential.user.email
          })
          .then( result => {
            this.go('Play') // Success!
          })
          .catch( err => {
            this.socialFeedback = err.message
            firebase.auth().signOut()
            this.overlay = false
          })
        } else {
          // Existing User. This just becomes a standard login.
          this.go('Play')
        }
      })
      .catch( err => {
        if (err.code == 'auth/popup-closed-by-user') {
          this.socialFeedback = 'Google authentication popup closed.'
        } else {
          this.socialFeedback = err.message
        }
        this.overlay = false
      })
    }
  }
}
</script>

<style scoped>
  .start-card {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
</style>