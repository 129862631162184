<template>
  <div class="editor-panels disable-select">
    <!-- ------------ User Interface ------------ -->
    <div class="secondary-panels">
      <div class="secondary-palette" ref="paletteContainer">
        <div class="secondary-palette-sprite-container" v-for="(sprite,index) in spritePalette" :key="'paletteSprite'+index" :class="(brushIndex == index) ? 'selected' : ''" @click="setBrush(index)">
          <img :src="sprite.path" :style="{left: (sprite.x*-48) + 'px', top: (sprite.y*-48) + 'px'}" />
        </div>
      </div>
    </div>
    <div class="primary-panels">
      <div class="primary-tools pt-2">
        <div class="primary-tools-header">
          <v-icon>mdi-tools</v-icon>
        </div>
        <div class="primary-tools-layers">
          <v-btn icon @click="resizeOverlay = true"><v-icon>mdi-resize</v-icon></v-btn>
          <v-btn v-if="grid" icon @click="grid = false; editorP5.setGrid(false)"><v-icon>mdi-grid</v-icon></v-btn>
          <v-btn v-if="!grid" icon @click="grid = true; editorP5.setGrid(true)"><v-icon>mdi-grid-off</v-icon></v-btn>
          <v-divider style="margin-bottom: 5px"></v-divider>
          <v-btn icon @click="editorP5.saveSprites()"><v-icon>mdi-animation-play-outline</v-icon></v-btn>
          <v-btn icon @click="editorP5.saveImages()"><v-icon>mdi-image-move</v-icon></v-btn>
          <v-btn icon @click="save()"><v-icon>mdi-content-save-outline</v-icon></v-btn>
          <!-- <v-btn icon @click="load()"><v-icon>mdi-folder-outline</v-icon></v-btn> -->
        </div>
      </div>
      <div class="primary-tools pt-2 last v-flex">
        <div class="primary-tools-header">
          <v-icon>mdi-layers-triple</v-icon>
        </div>
        <div class="primary-tools-layers">
          <div class="d-row" v-for="(layer,index) in layers" :key="'layer'+index">
            {{ index }}
            <v-btn icon :color="activeLayer == index ? 'blue' : ''" @click="activeLayer = index"><v-icon>mdi-layers</v-icon></v-btn>
            <v-btn icon @click="layer.visible = !layer.visible"><v-icon @click="editorP5.refresh()">{{ layer.visible == true ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon></v-btn>
          </div>
        </div>
      </div>
      <div class="primary-canvas" ref="canvasContainer" id="canvasContainer"></div>
      <div class="primary-panel" :style="{ height: computedHeight + 'px'}">
        <div class="primary-panel-tabs" id="primaryPanelTabs">
          <div v-for="(s,index) in spritesheets" :class="activeSpritesheet == index ? 'active': ''" :key="'tab' + index" class="primary-panel-tab" @click="changeSpritesheet(index)">
            {{ index }}
          </div>
        </div>
        <div id="primaryPanelImage" class="primary-panel-image" ref="spritesheetImageContainer">
          <img v-if="activeSpritesheet != null" ref="spritesheetImage" :src="spritesheets[activeSpritesheet].path" />
        </div>
      </div>
    </div>
    <div class="tertiary-panels">
      <div class="tertiary-bar">Bleep Bloop: Level Editor - {{ levelName }} ({{ id }})</div>
    </div>
    <!-- ------------ Startup Overlay ------------ -->
    <v-overlay :absolute="true" :value="overlay" :opacity="0.8">
      <v-card dark min-width="500">
        <v-progress-linear v-if="loading" :indeterminate="true"></v-progress-linear>
        <v-card-title>Level Editor</v-card-title>
        <v-card-text v-if="!loading">
          <v-select
            v-if="loadedLevels"
            v-model="loadLevelData"
            :items="loadedLevels"
            label="Load Level"
            dark
            return-object
          ></v-select>
          <v-card-actions>
            <v-spacer />
            <v-btn dark @click="init(true, loadLevelData)" :disabled="!loadLevelData">
              Load Level
            </v-btn>
          </v-card-actions>
        </v-card-text>
        <v-divider v-if="!loading" />
        <v-card-text v-if="!loading">
          <v-text-field v-model="newLevelName"></v-text-field>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="init(false, newLevelName)" :disabled="!newLevelName">
              Create New Level
            </v-btn>
          </v-card-actions>
        </v-card-text>
        <v-card-text v-if="loading">
          Initializing...
        </v-card-text>
      </v-card>
    </v-overlay>
    <!-- ------------ Saving Overlay ------------ -->
    <v-overlay :absolute="true" :value="savingOverlay" :opacity="0.8">
      <v-card>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
        <v-card-title>
          Saving
        </v-card-title>
        <v-card-text>
          Please wait while we save your level...
        </v-card-text>
      </v-card>
    </v-overlay>
    <!-- ------------ Resize Overlay ------------ -->
    <v-overlay :absolute="true" :value="resizeOverlay" :opacity="0.8">
      <v-card light min-width="500">
        <v-card-title>
          Resize Grid
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="px-4">
              <v-range-slider
                v-model="dimensions"
                :min="gridSizeMin"
                :max="gridSizeMax"
                hide-details
                color="blue"
                class="align-center"
              >
                <template v-slot:prepend>
                  <v-text-field
                    :value="dimensions[0]"
                    class="mt-0 pt-0"
                    color="blue"
                    hint="Height"
                    persistent-hint
                    :disabled="true"
                    type="number"
                    style="width: 60px"
                  ></v-text-field>
                </template>
                <template v-slot:append>
                  <v-text-field
                    :value="dimensions[1]"
                    class="mt-0 pt-0"
                    color="blue"
                    hint="Width"
                    persistent-hint
                    :disabled="true"
                    type="number"
                    style="width: 60px"
                  ></v-text-field>
                </template>
              </v-range-slider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="resizeOverlay = false">Cancel</v-btn>
          <v-spacer />
          <v-btn @click="resize()" color="blue" dark>Proceed</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
    <v-snackbar v-model="snackbar" :timeout="2000">
      Level saved successfully
    </v-snackbar>
  </div>
</template>
<script>
import db from '@/firebase/firestore'
import p5 from 'p5'
export default {
  name: 'Editor',
  data() {
    return {
      // Operational Variables
      overlay: true,
      savingOverlay: false,
      resizeOverlay: false,
      gridSizeMin: 10,
      gridSizeMax: 100,
      dimensions: [40, 25], // default
      snackbar: false,
      computedHeight: 0,
      computedWidth: 0,
      newLevelName: 'untitled',
      loadLevelData: null,
      loadedLevels: null,
      loading: false,
      editorP5: null,
      grid: true,
      // Application Variables
      id: null,
      layers: [
        {visible: true, type: 'sprite'},
        {visible: true, type: 'sprite'},
        {visible: true, type: 'sprite'},
        {visible: true, type: 'sprite'},
        {visible: true, type: 'sprite'}
      ],
      activeLayer: 0,
      focusLayer: false,
      levelName: null,
      spritesheets: [
        {name: 'Room Builder', path: '/assets/sprites/Modern_Interiors/1_Interiors/48x48/Room_Builder_48x48.png', loaded: false, image: null},
        {name: 'Room Builder', path: '/assets/sprites/Modern_Interiors/1_Interiors/48x48/Interiors_48x48.png', loaded: false, image: null},
        {name: 'Room Builder', path: '/assets/sprites/Modern_Interiors/1_Interiors/48x48/Theme_Sorter_48x48/1_Generic_48x48.png', loaded: false, image: null},
        {name: 'Room Builder', path: '/assets/sprites/Modern_Interiors/1_Interiors/48x48/Theme_Sorter_48x48/19_Hospital_48x48.png', loaded: false, image: null},
        {name: 'Office Interior', path: '/assets/sprites/Modern_Office/interiors.png', loaded: false, image: null},
        {name: 'Office Floors', path: '/assets/sprites/Modern_Office/walls_floors.png', loaded: false, image: null},
        {name: 'Scerene Village', path: '/assets/sprites/Serene_Village/Serene_Village_48x48.png', loaded: false, image: null}
      ],
      activeSpritesheet: null,
      spritePalette: [],
      brushIndex: 0,
    }
  },
  mounted() {
    // ------------ Garbage Collection ------------
    let canvases = document.getElementsByClassName('p5Canvas')
    if (canvases.length > 1) window.location.reload()
    // ------------ Define Canvas Size ------------
    this.computedWidth = this.$refs.canvasContainer.clientWidth
    this.computedHeight = this.$refs.canvasContainer.clientHeight
    // ------------ Load Levels ------------
    // db.collection('levels').doc('mEWOFSey5ib0NTQUrwci').get().then( r => {
    //   console.log('deleted.', r)
    // })
    db.collection('levels').get().then( snapshot => {
      let levels = []
      if (!snapshot.empty) {
        snapshot.docs.forEach( doc => {
          let data = doc.data()
          data.id = doc.id
          let level = {
            text: data.name,
            value: data
          }
          levels.push(level)
        })
        this.loadedLevels = levels
      }
    })
    // ------------ Load Spritesheets ------------
    let loaded = 0
    this.spritesheets.forEach( spritesheet => {
      let img = new Image()
      img.onload = (event) => {
        spritesheet.loaded = true
        spritesheet.width = img.width
        spritesheet.height = img.height
        spritesheet.image = img
        loaded++
      }
      img.src = spritesheet.path
    })
    // ------------ Wait for Spritesheets to Load ------------
    let timer = setInterval(() => {
      if (loaded == this.spritesheets.length) {
        clearInterval(timer)
        this.changeSpritesheet(0)
      }
    }, 500)
  },
  methods: {
    loaded() {
      this.loading = false
      this.overlay = false
    },
    save() {
      this.savingOverlay = true
      // ------------ Create Record ------------
      let record = {
        name: this.levelName,
        grid: this.editorP5.getGrid(),
        sprites: this.spritePalette,
        tiles: []
      }
      // ------------ Collapse Layers ------------
      let layers = this.editorP5.getLayers()
      for (var l=0; l<layers.length; l++) {
        for (var y=0; y<layers[l].length; y++) {
          for (var x=0; x<layers[l][y].length; x++) {
            if (layers[l][y][x] != null) {
              record.tiles.push({
                x: x,
                y: y,
                layer: l,
                sprite: layers[l][y][x]
              })
            } 
          }
        }
      }
      if (this.id) {
        console.log('Updating...')
        // ------------ Update Existing Record ------------
        db.collection('levels').doc(this.id).set(record).then( result => {
          this.savingOverlay = false
          this.snackbar = true
        }).catch( err => {
          alert('Error. Could not save your level! Check the logs.')
          console.log('update error', err)
          this.savingOverlay = false
        })
      } else {
        console.log('Creating...')
        // ------------ Add New Record to Firebase ------------
        db.collection('levels').add(record).then( result => {
          this.id = result.id
          this.savingOverlay = false
          this.snackbar = true
        }).catch( err => {
          alert('Error. Could not save your level! Check the logs.')
          console.log('add error', err)
          this.savingOverlay = false
        })
      }
    },
    resize() {
      this.editorP5.resizeGrid(this.dimensions[1],this.dimensions[0])
      this.resizeOverlay = false
    },
    changeSpritesheet(index) {
      if (index != this.activeSpritesheet) {
        this.activeSpritesheet = index
        let container = this.$refs.spritesheetImageContainer
        // ------------ Remove Gridlines ------------
        document.querySelectorAll('.horizontalLine').forEach(e => e.remove())
        document.querySelectorAll('.verticalLine').forEach(e => e.remove())
        // ------------ Draw Gridlines ------------
        let horizontalGridlines = Math.floor(this.spritesheets[index].height/48)
        let verticalGridlines = Math.floor(this.spritesheets[index].width/48)
        for (let x=1; x<verticalGridlines; x++) {
          // ------ Vertical Gridlines ------
          var verticalLine = document.createElement('div')
          verticalLine.className = 'verticalLine'
          verticalLine.style.cssText = 'left: ' + x*48 + 'px; height: ' + this.spritesheets[index].height + 'px'
          container.appendChild(verticalLine)
        }
        for (let y=1; y<horizontalGridlines; y++) {
          // ------ Horizontal Gridlines ------
          var horizontalLine = document.createElement('div')
          horizontalLine.className = 'horizontalLine'
          horizontalLine.style.cssText = 'top: ' + y*48 + 'px; width: ' + this.spritesheets[index].width + 'px'
          container.appendChild(horizontalLine)
        }
      }
    },
    addSpriteToPalette(x,y,spritesheetIndex = this.activeSpritesheet) {
      // ------------ Check for Duplicate Sprite ------------
      let match = false
      this.spritePalette.forEach( (sprite,index) => {
        if (sprite.x == x && sprite.y == y && sprite.path == this.spritesheets[spritesheetIndex].path) {
          match = true
          this.setBrush(index)
        }
      })
      // ------------ Add Sprite to Palette ------------
      if (!match) {
        // ------------ New Sprite Selected ------------
        this.spritePalette.push({
          path: this.spritesheets[spritesheetIndex].path,
          x: x,
          y: y,
          spritesheetIndex: spritesheetIndex
        })
        this.setBrush(this.spritePalette.length-1)
      }
      return !match
    },
    setBrush(index) {
      this.brushIndex = index
    },
    init(loading, data) {
      this.loading = true
      this.levelName = loading ? data.value.name : data
      this.id = loading ? data.value.id : null
      // ------------ Make Vue Accessible ------------
      let _this = this // !important
      // ------------ Initialize Screen ------------
      let width = this.$refs.canvasContainer.clientWidth
      let height = this.$refs.canvasContainer.clientHeight
      // ------------ Initialize Grid ------------
      var grid = {
        width: 40,
        height: 25,
        cell: { width: 48, height: 48 }
      }
      if (loading) grid = data.value.grid
      // ------------ Initialize Layers ------------
      var layers = []
      for (var i=0; i<this.layers.length; i++) {
        layers[i] = []
        for( var y=0; y<grid.height; y++) {
          layers[i][y] = []
          for( var x=0; x<grid.width; x++) {
            if (loading) {
              for (var t=0; t<data.value.tiles.length; t++) {
                let tile = data.value.tiles[t]
                if (tile.layer == i && tile.x == x && tile.y == y) {
                  layers[i][y][x] = tile.sprite // match found
                }
              }
            } else {
              layers[i][y][x] = null // Default to empty
            }
          }
        }
      }
      // ---------------------- Initialize Sprites ----------------------
      var loadingSprites = loading ? data.value.sprites : []
      for (var i=0; i<loadingSprites.length; i++) {
        // ------------ Add Sprites to Palette ------------
        this.addSpriteToPalette(loadingSprites[i].x,loadingSprites[i].y,loadingSprites[i].spritesheetIndex)
      }
      // ---------------------- Create P5 Instances ----------------------
      const editorP5 = function (p5) {
        var redraw = true
        var showGrid = true
        var spritesheets = []
        var sprites = []
        var screen
        var camera

        // =-=-=-=-=-=-=-=-=-=-=- PRELOAD -=-=-=-=-=-=-=-=-=-=-=
        p5.preload = _ => {
          // ---------------------- Preload Spritesheets ----------------------
          for (var i=0; i<_this.spritesheets.length; i++) {
            spritesheets[i] = p5.loadImage(_this.spritesheets[i].path)
          }
        }
        
        // =-=-=-=-=-=-=-=-=-=-=- SETUP -=-=-=-=-=-=-=-=-=-=-=
        p5.setup = _ => {
          // ------------ Initialize Canvas ------------
          var canvas = p5.createCanvas(width, height)
          canvas.parent("canvasContainer")
          // ------------ Initialize Screen ------------
          screen = {
            x: 0,
            y: 0,
            width: p5.width,
            height: p5.height
          }
          // ------------ Initialize Camera ------------
          camera = {
            x: 0,
            y: 0,
            width: screen.width,
            height: screen.height
          }
          p5.moveCamera() // Initiates Bounds
          // ------------ Initialize Sprites ------------
          // sprites is overloaded. If it is an array of data then we replace them with the
          // actual images. If it is an empty array then it is initialized for a new level.
          // _this.spritesheets[]: contains json data of each sheet
          // spritesheets[]: contains the actual spritesheet image data
          for (var i=0; i<loadingSprites.length; i++) {
            let index = loadingSprites[i].spritesheetIndex
            let x = loadingSprites[i].x*48
            let y = loadingSprites[i].y*48
            // p5.image(spritesheets[index],-x,-y)
            let sprite = p5.createImage(48,48)
            sprite.copy(spritesheets[index],x,y,48,48, 0,0,48,48)
            // p5.image(sprite,i*48,48)
            sprites.push(sprite)
          }
          // ------------ Loading Complete ------------
          _this.loaded()
        }

        // =-=-=-=-=-=-=-=-=-=-=- DRAW -=-=-=-=-=-=-=-=-=-=-=
        p5.draw = _ => {
          if (redraw) {
            // ------------ Clear Screen ------------
            p5.background('#263238')
            // --- Debug: Draw Sprites ---
            // for( var i=0; i<sprites.length; i++) {
            //   p5.image(sprites[i],i*48,0)
            // }
            // ------------ Draw Grid ------------
            for (var i=0; i<_this.layers.length; i++) {
              if( _this.layers[i].visible ) {
                for (var y=camera.bounds.lowerY; y<camera.bounds.upperY; y++) {
                  for ( var x=camera.bounds.lowerX; x<camera.bounds.upperX; x++) {
                     if (x < grid.width && y < grid.height) {
                      let screenX = x * grid.cell.width
                      let screenY = y * grid.cell.height
                      let spriteIndex = layers[i][y][x]
                      if (spriteIndex == null) {
                        // ------------ Nothing to Render ------------
                        if (showGrid) {
                          p5.stroke('grey')
                          p5.noFill()
                          p5.rect(
                            screenX-camera.x,
                            screenY-camera.y,
                            grid.cell.width,
                            grid.cell.height
                          )
                        }
                      } else {
                        // ------------ Render Sprite ------------
                        if (sprites[spriteIndex] != undefined) {
                          p5.image(
                            sprites[spriteIndex],
                            screenX-camera.x,screenY-camera.y,
                            grid.cell.width,grid.cell.height
                          )
                        }
                      }
                    } // Bounds
                  } // x
                } // y
              } // visible
            } // layer
            redraw = false
          } // redraw

          // ------------ Keyboard Input ------------
          if( p5.keyIsDown(p5.RIGHT_ARROW)) p5.moveCamera('right')
          if( p5.keyIsDown(p5.LEFT_ARROW))  p5.moveCamera('left')
          if( p5.keyIsDown(p5.UP_ARROW))    p5.moveCamera('up')
          if( p5.keyIsDown(p5.DOWN_ARROW))  p5.moveCamera('down')
          if( p5.keyIsDown(68)) p5.moveCamera('right')
          if( p5.keyIsDown(65)) p5.moveCamera('left')
          if( p5.keyIsDown(87)) p5.moveCamera('up')
          if( p5.keyIsDown(83)) p5.moveCamera('down')
        }

        // =-=-=-=-=-=-=-=-=-=-=- Mouse Moved -=-=-=-=-=-=-=-=-=-=-=
        p5.mouseMoved = (event) => {
          if (event.metaKey) { // Cmd + Move
            let canvasRect = p5.canvas.getBoundingClientRect()
              if (sprites.length && p5.contains(canvasRect,event.x,event.y)) {
                // ------------ Canvas Clicked ------------
                let mx = Math.floor((event.clientX + camera.x - canvasRect.x)/48)
                let my = Math.floor((event.clientY + camera.y - canvasRect.y)/48)
                if (event.shiftKey) { // Cmd + Shift + Move
                  layers[_this.activeLayer][my][mx] = null
                } else {
                  layers[_this.activeLayer][my][mx] = _this.brushIndex
                }
                redraw = true
              }
          }
        }

        // =-=-=-=-=-=-=-=-=-=-=- Mouse Clicked -=-=-=-=-=-=-=-=-=-=-=
        p5.mouseClicked = (event) => {
          if (p5.canvas == undefined) return false
          if (_this.resizeOverlay) return false
          let canvasRect = p5.canvas.getBoundingClientRect()
          let panelRect = document.getElementById('primaryPanelImage').getBoundingClientRect()
          if (p5.contains(canvasRect,event.x,event.y)) {
            // ------------ Canvas Clicked ------------
            let mx = Math.floor((event.clientX + camera.x - canvasRect.x)/48)
            let my = Math.floor((event.clientY + camera.y - canvasRect.y)/48)
            // console.log('canvas', mx, my, event)
            if (sprites.length) {
              if (event.shiftKey) { // Shift + Click
                // ------------ Delete Sprite ------------
                layers[_this.activeLayer][my][mx] = null
              } else { // Standard Click
                // ------------ Place Sprite ------------
                layers[_this.activeLayer][my][mx] = _this.brushIndex
              }
              redraw = true
            } else {
              // console.log('no brush')
            }
          } else if (p5.contains(panelRect,event.x,event.y)) {
            // ------------ Panel Clicked ------------
            let spritesheetRect = _this.$refs.spritesheetImage.getBoundingClientRect()
            let mx = Math.floor((event.clientX - spritesheetRect.left)/48)
            let my = Math.floor((event.clientY - spritesheetRect.top)/48)
            // console.log('panel', mx, my)
            // ------------ Add to Palette ------------
            if (_this.addSpriteToPalette(mx,my)) {
              // ------------ Create Sprite ------------
              let sprite = p5.createImage(48,48)
              sprite.copy(spritesheets[_this.activeSpritesheet],mx*48,my*48,48,48, 0,0,48,48)
              sprites.push(sprite)
              //
              redraw = true
            }
          } else {
            // console.log('other')
          }
        }

        p5.keyPressed = (event) => {
          // ------------ Screenshot ------------
          switch (event.code) {
            case 'KeyT':
              // ---------------------- Test Key ----------------------
              break;
          }
        }

        // =-=-=-=-=-=-=-=-=-=-=- Save Images -=-=-=-=-=-=-=-=-=-=
        p5.saveImages = _ => {
          for (var i=0; i<layers.length; i++) {
            if (_this.layers[i].visible) {
              let graphics = p5.createGraphics(grid.width*grid.cell.width, grid.height*grid.cell.height)
              for (var y=0; y<layers[i].length; y++) {
                for ( var x=0; x<layers[i][y].length; x++) {
                  let screenX = x * grid.cell.width
                  let screenY = y * grid.cell.height
                  let spriteIndex = layers[i][y][x]
                  // ------------ Render Sprite ------------
                  if (sprites[spriteIndex] != undefined) {
                    graphics.image(
                      sprites[spriteIndex],
                      screenX-camera.x,screenY-camera.y,
                      grid.cell.width,grid.cell.height
                    )
                  }
                } // x
              } // y
              p5.saveCanvas(graphics, _this.levelName + '-layer' + i, 'png')
            }
          } // layer
        }

        p5.saveSprites = _ => {
          console.log('Exporting spritesheet')
          if (sprites.length) {
            let width = (sprites.length < 10) ? sprites.length * grid.cell.width : 10 * grid.cell.width
            let height = Math.ceil(sprites.length / 10) * grid.cell.height            
            let graphics = p5.createGraphics(width,height)
            // console.log('spritesheet', width, height)
            for (var i=0; i<sprites.length; i++) {
              let sx = (i % 10) * grid.cell.width
              let sy = Math.floor(i / 10) * grid.cell.height
              graphics.image(sprites[i], sx, sy)
            }
            p5.saveCanvas(graphics, _this.levelName + '-spritesheet', 'png')
          }
        }

        // =-=-=-=-=-=-=-=-=-=-=- Helper Functions -=-=-=-=-=-=-=-=-=-=-=
        // ---------------------- External Get Grid ----------------------
        p5.getGrid = _ => { return grid }
        // ---------------------- External Resize Grid ----------------------
        p5.resizeGrid = (width,height) => {
          console.log('resizing', width, height)
          // ------------ Prepare Resized Layers ------------
          let resizedLayers = []
          for (var i=0; i<layers.length; i++) {
            resizedLayers[i] = []
            for (var y=0; y<height; y++) {
              resizedLayers[i][y] = []
              for ( var x=0; x<width; x++) {
                if( layers[i][y] != undefined && layers[i][y][x] != undefined) {
                  resizedLayers[i][y][x] = layers[i][y][x]
                } else {
                  resizedLayers[i][y][x] = null
                }
              }
            }
          }
          // ------------ Perform Resize ------------
          grid.width = width
          grid.height = height
          camera.x = 0
          camera.y = 0
          p5.moveCamera() // Initiates Bounds
          layers = resizedLayers // swap layers
          redraw = true
          console.log('resized.')
        }
        // ---------------------- External Get Layers ----------------------
        p5.getLayers = _ => { return layers }
        // ---------------------- External Redraw ----------------------
        p5.refresh = _ => { redraw = true }
        // ---------------------- External Grid Toggle ----------------------
        p5.setGrid = (state) => { showGrid = state; p5.refresh() }
        // ---------------------- Point in Rect ----------------------
        p5.moveCamera = (direction) => {
          // ------------ Change Direction ----------
          // Benchmarked for performance
          if (direction == 'up') camera.y -= 10;
          else if (direction == 'down') camera.y += 10;
          else if (direction == 'left') camera.x -= 10;
          else if (direction == 'right') camera.x += 10;
          // ------------ Impose Boundaries ------------
          if ( camera.x > grid.width*grid.cell.width-screen.width) {
            camera.x = grid.width*grid.cell.width-screen.width
          }
          if ( camera.x < 0) camera.x = 0
          if ( camera.y > grid.height*grid.cell.height-screen.height) {
            camera.y = grid.height*grid.cell.height-screen.height
          }
          if ( camera.y < 0) camera.y = 0
          // ------------ Recalculate Bounds ------------
          camera.bounds = {
            lowerX: Math.floor(camera.x / grid.cell.width),
            lowerY: Math.floor(camera.y / grid.cell.height),
            upperX: Math.ceil((camera.x + camera.width)/grid.cell.width),
            upperY: Math.ceil((camera.y + camera.height)/grid.cell.height)
          }
          // ------------ Initiate Redraw ------------
          redraw = true
        }
        
        // ---------------------- Point in Rect ----------------------
        p5.contains = function (rect, x, y) {
          return rect.x <= x && x <= rect.x + rect.width &&
                 rect.y <= y && y <= rect.y + rect.height
        }
      }
      this.editorP5 = new p5(editorP5)
    },
  }
}
</script>
<style lang="scss">
  .editor-panels {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .disable-select {
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
  }
  .primary-panels {
    flex: 1;
    display: flex;
    flex-direction: row;
    .primary-tools {
      background: white;
      border-right: 1px solid #efefef;
      width: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .primary-tools-header {
      border-bottom: 1px solid #efefef;
      width: 100%;
      display: flex;
      padding-bottom: 6px;
      justify-content: center;
    }
    .primary-tools-layers {
      display: flex;
      flex-direction: column-reverse;
      padding-top: 8px;
      font-size: 10px;
    }
    .primary-tools.last {
      border-right: 1px solid #c4c4c4;
      width: 100px;
    }
    .primary-canvas {
      background: #efefef;
      flex: 1;
    }
    .primary-panel {
      display: flex;
      flex-direction: column;
    }
    .primary-panel-tabs {
      border-bottom: 1px solid #c4c4c4;
      border-left: 1px solid #c4c4c4;
      display: flex;
      flex-direction: row;
      padding-top: 4px;
      background: #efefef;
      .primary-panel-tab {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #c4c4c4;
        background: white;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom: none;
        margin-right: 2px;
        margin-left: 2px;
      }
      .primary-panel-tab.active {
        background: #2296f3;
        color: white
      }
      .primary-panel-tab:hover {
        cursor: pointer;
        background: #efefef;
      }
    }
    .primary-panel-image {
      flex: 1;
      // background: url('/assets/img/dot-grid.png');
      background: url('/assets/img/topography.png');
      width: 480px;
      overflow: auto;
      position: relative;
      border-left: 1px solid #c4c4c4;
      img {
        position: absolute;
        z-index: 0;
      }
      .horizontalLine {
        position: absolute;
        background: #bfcdd0;
        height: 1px;
        z-index: 1;
      }
      .verticalLine {
        position: absolute;
        background: #bfcdd0;
        width: 1px;
        z-index: 1;
      }
    }
  }
  .secondary-panels {
    border-bottom: 1px solid #c4c4c4;
    .secondary-palette {
      background:#333;
      min-height: 55px;
      display: flex;
      align-items: center;
      background: url('/assets/img/topography.png');
      padding: 0 3px;
      overflow: scroll;
      .secondary-palette-sprite-container {
        width: 48px;
        min-width: 48px;
        height: 48px;
        position: relative;
        overflow: hidden;
        border: 1px solid #333;
        margin-right: 3px;
        img {
          position: absolute;
        }
      }
      .secondary-palette-sprite-container.selected {
        border: 3px solid #2296f3;
      }
    }
  }
  .tertiary-panels {
    border-top: 1px solid #c4c4c4;
    .tertiary-bar {
      padding: 2px 10px;
      font-size: 10px;
    }
  }
</style>