import firebase from 'firebase'
import firestore from 'firebase/firestore'

// Initialize Firebase
var firebaseConfig = {
  apiKey: "AIzaSyDRYNyYLh-xaSnr3iTRyiEaRISQJGB6vRg",
  authDomain: "bleep-bloop-development.firebaseapp.com",
  projectId: "bleep-bloop-development",
  storageBucket: "bleep-bloop-development.appspot.com",
  messagingSenderId: "159112565048",
  appId: "1:159112565048:web:7ae17eabfb7f3a1463b1cd"
};

const firebaseApp = firebase.initializeApp(firebaseConfig)
export default firebaseApp