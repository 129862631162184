<template>
  <div class="creative engine disable-select">
    <div class="header">header<!-- header content --></div>
    <div class="main">
      <div class="main screen highlight">
        <div class="main screen overlay"><!-- overlay --></div>
        <canvas id="screen-canvas" class="canvas highlight2"></canvas>
      </div>
      <div class="main panel highlight">
        <div class="main panel overlay"><!-- overlay content --></div>
        <div>tabs</div>
        <canvas id="panel-canvas" class="canvas highlight2"></canvas>
      </div>
    </div>
    <div class="footer">footer<!-- footer content --></div>
  </div>
</template>

<script>
import db from '@/firebase/firestore'
import p5 from 'p5'
// 31,283 of 81,925
class Engine {
  constructor(vue) {
    this.vue = vue
    // console.log(vue)
    // vue.test()
  }
}

class Creative extends Engine {
  constructor(vue) {
    super(vue)
  }
}

export default {
  name: 'Story',
  data() {
    return {
      engine: null
    }
  },
  mounted() {
    this.engine = new Creative(this)
  },
  methods: {
    test() {
      alert('vue test')
    }
  }

}
</script>

<style lang="scss" scoped>
  .highlight {
    border: 1px solid red;
    background: yellow;
  }
  .highlight2 {
    border: 1px solid greenyellow;
    background: grey;
  }
  .engine {
    display: flex;
    flex-direction: column;
    height: 100%;
    .header {

    }
    .main {
      display: flex;
      flex: 1;
      flex-direction: row;
      .screen {
        display: flex;
        flex: 2;
        flex-direction: column;
        position: relative;
      }
      .panel {
        display: flex;
        flex: 1;
        flex-direction: column;
        max-width: 500px;
        position: relative;
      }
      canvas {
        width: 100%;
        height: 100%;
      }
    }
    .footer {

    }
  }
  .disable-select {
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
  }
</style>